import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';

const accessToken = userStore.getState().user.accessToken

const visatypeCountService = {
   
    notInterestedOrQualified: async (data) => {
        return apiRequest({
            method: "POST",
            url: `/api/visatypecount/notinterestedorqualified`,
            data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },
   
    myClientsCount: async (data) => {
        return apiRequest({
            method: "POST",
            url: `/api/visatypecount/myclient`,
            data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    }

}

export default visatypeCountService