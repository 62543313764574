import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import LoaderCommon from '../Common/LoaderCommon'
import { decode } from 'html-entities'
import moment from 'moment'
import PaginationComponent from '../Common/PaginationComponent'
import { Empty } from 'antd'
import LeadStatus from '../Common/LeadStatus'
import ProspectStatusList from '../Common/ProspectStatusList'
import { usePaginationStore } from '../../store/userStore'
import authQueries from '../../queries/authQueries'
import ConfirmProgram from '../Common/ConfirmProgram'
import RejectProgram from '../Common/RejectProgram'
import { useApi } from '../Common/ApiProvider'
import ApplicantViewButton from '../Common/ApplicantViewButton'

const PendingProgramListing = () => {

    const [result, setResult] = useState([])
    const [loader, setLoader] = useState(true)

    const {apiData} = useApi()

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const listApplicants = authQueries.useProgramPendingList(
        (response) => {
            setLoader(false)
            setResult(response)
        }
    );

    useEffect(() => {
        listApplicants.mutateAsync({ page: currentPage, size: 25 })
    }, [apiData, currentPage]);
    

  return (
    <Fragment>
        <div className="add_clients">
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <h2><span>Pending</span>Programs</h2>
                </div>
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>

            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header flex justify-between">
                        <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                            Pending Programs
                        </a>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">

                            <div className="table-list-search-wrpr simple-search">
                                <div className="search-results-count-wrpr">
                                    {result?.data?.totalItems > 0 ?
                                    `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                    `O results found`}
                                </div>

                                {
                                    loader ? 
                                    <LoaderCommon />:
                                    result?.data?.totalItems > 0 ?
                                    <div className="table-wrpr">
                                        <div className="table-section">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table prospect-table">
                                                        <thead>
                                                            <tr className='alt-colors'>
                                                                <th width="30">#</th>
                                                                <th>Applicant Name</th>
                                                                <th>Contact Number</th>
                                                                <th>Visa Type</th>
                                                                <th>Counselor</th>
                                                                <th>University</th>
                                                                <th>Program Name</th>
                                                                <th>Campus</th>
                                                                <th>Intake</th>
                                                                <th width='150'>&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="ui-sortable">
                                                            {result?.data?.items?.map((item, index) => {
                                                                const applicant = item?.application
                                                                const assign = applicant?.assignUser?.CounselorAssign?.userdetail
                                                                return(
                                                                    <Fragment key={index}>
                                                                        <tr>
                                                                            <td className="">{(index + 1) + (result.data?.currentPage * 25)}</td>
                                                                            <td>
                                                                                {decode(applicant?.applicant_name)} {decode(applicant?.middlename)} {decode(applicant?.lastname)}
                                                                            </td>
                                                                            <td>
                                                                                <div className="email-mobile flex flex-col">
                                                                                    <div>{applicant?.mobile_no}</div>
                                                                                    <div>{applicant?.landline_no}</div>
                                                                                    <div>{applicant?.whatsapp_no}</div>
                                                                                </div>
                                                                            </td>
                                                                            <td>{applicant?.visatype?.description}</td>
                                                                            <td>{assign?.firstname} {assign?.lastname}</td>
                                                                            <td>{item?.college?.name}</td>
                                                                            <td>{item?.program?.name}</td>
                                                                            <td>{item?.campus?.name}</td>
                                                                            <td>{item?.intake?.intake}</td>
                                                                            <td className='flex justify-end'>
                                                                                <div className='flex gap-2 items-center justify-center'>
                                                                                    <div className='w-[65px]'>
                                                                                        <ConfirmProgram item={item} applicant={applicant} />
                                                                                    </div>
                                                                                    <div className='w-[50px]'>
                                                                                        <RejectProgram item={item} applicant={applicant} />
                                                                                    </div>
                                                                                    <div className='w-[45px]'>
                                                                                        <ApplicantViewButton item={applicant} />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className='mt-24 mb-24'>
                                        <Empty
                                        description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>Clients not found!</div>}
                                        />
                                    </div>
                                }

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </Fragment>
  )
}

export default PendingProgramListing