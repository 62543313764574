import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';

const accessToken = userStore.getState().user.accessToken

const callCenterService = {
   
    getApplicant: async (data) => {
        return apiRequest({
            method: "POST",
            url: "/api/callcenter/applicant",
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },
    
    getCounselor: async (data) => {
        return apiRequest({
            method: "POST",
            url: "/api/callcenter/counselor",
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },
    
    getCounselorListDropdown: async () => {
        return apiRequest({
            method: "GET",
            url: "/api/callcenter/counselor/dropdown",
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },
    
    getSmartLogListing: async (data) => {
        return apiRequest({
            method: "POST",
            url: "/api/callcenter/smart_log",
            data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

}

export default callCenterService