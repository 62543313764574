import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link } from "react-router-dom";
import moment from "moment";
import { IconPlaneDeparture } from "@tabler/icons-react";
import LoaderCommon from "../../Common/LoaderCommon";
import LeadStatus from "../../Common/LeadStatus";
import PaginationComponent from "../../Common/PaginationComponent";
import { usePaginationStore } from "../../../store/userStore";
import AdvancedSearch from "../../Common/AdvancedSearch";
import { useAdvancedSearch } from "../../../store/advancedSearchStore";
import ApplicantViewButton from "../../Common/ApplicantViewButton";
import ApplicationStatusModal from "../../Common/ApplicationStatusModal";
import { decode } from "html-entities";


const VisaApprovedReport =()=>{

    const [result, setResult] = useState([]);
    const [active, setActive] = useState({ index: null, status: false });

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const data = useAdvancedSearch((state) => state);

    const visaReport = authQueries.useCEOVisaApprovedMutation(
        (response) => {
            setResult(response);   
        }
    );


    useEffect(()=>{
        visaReport.mutateAsync({
            page: currentPage,
            size: 100,
            ...data,
        });
    },[data, currentPage]);

    const counselorName =(counselor)=>{
        if(!counselor){
            return 'N/A'
        }
        return `${counselor?.firstname} ${counselor?.lastname}`
    }

    const offerAcceptedProgram =(item)=>{
        if(item?.length > 0){
            const program = item?.find(element => element?.sid === 7)
            if(program){
                return program
            }else{
                return null
            }
        }
    }

    const expantButton = (index) => {
        if (active.index === index) {
            setActive({
                index: null,
                status: false
            })
        } else {
            setActive({
                index: index,
                status: true
            })
        }
    }

    return(
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Visa Approved clients</li>
                </ol>
                </nav>
            </div>
            
            <div className="add_clients">
                <div className="flex items-center gap-[4px] mb-4">
                    <i className="followup-icon">
                        <IconPlaneDeparture size="25" className='text-[#7D6FAB]' />
                    </i>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                            <span className="text-[16px] font-medium">Visa Approved Report</span>	
                        </div>
                    </div>
                </div>
                <div id="accordion" className="add_clients_accordion_form mt-[10px]">
                    <div  className="card" id="appointments_wrpr_employee">
                        <div className="card-header">
                            Visa Approved Report
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">

                            <AdvancedSearch report={true} visaapproved={true} />

                            <div className="search-results-count-wrpr">
                                {
                                result?.data?.totalItems > 0 ?
                                `Search Results ${result?.data?.currentPage * 100 + 1}-${result?.data?.currentPage * 100 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                `O results found`
                                }
                            </div>

                            {visaReport.isLoading ?
                                <LoaderCommon />:
                                <div className="table-wrpr">
                                    <div className="table-section">
                                        <div className="row">
                                            <div className="col-md-12">                                   
                                                <table border={1} className="table prospect-table">
                                                    <thead>
                                                        <tr className="!font-bold">
                                                            <th width="30">#</th>
                                                            <th>Profile Status</th>
                                                            <th>Office</th>
                                                            <th>Date Created</th>
                                                            <th>Applicant Name</th>
                                                            <th>Contact Number</th>
                                                            <th>Visa Type</th>
                                                            <th>Intake</th>
                                                            <th>Counselor</th>
                                                            <th>Reg. Date</th>
                                                            <th>Visa approved on</th>
                                                            <th>Prospect Status</th>
                                                            <th>Referred By</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody  className="ui-sortable">
                                                        {   
                                                            result?.data?.items?.map((item, index) =>{
                                                            const application = item?.application
                                                            const program = offerAcceptedProgram(application?.intended_programs)
                                                            return(
                                                                <Fragment key={index}>
                                                                    <tr className="ui-sortable-handle">
                                                                        <td>{(index + 1) + (result?.data?.currentPage * 100)}</td>
                                                                        <td><LeadStatus item={application}/></td>
                                                                        <td>{application?.office?.name}</td>
                                                                        <td>{moment(application?.createdAt).format('DD-MM-YYYY')}</td>
                                                                        <td>
                                                                        {
                                                                            program ?
                                                                            <a style={{ cursor: 'pointer', color: '#1000FF' }} onClick={() => { expantButton(index) }}>
                                                                                {decode(application?.applicant_name)} {decode(application?.middlename)} {decode(application?.lastname)} <i className='icon-arrow-down-thick'></i>
                                                                            </a> :
                                                                            <Fragment>{decode(application?.applicant_name)} {decode(application?.middlename)} {decode(application?.lastname)}</Fragment>
                                                                        }
                                                                        </td>
                                                                        <td>
                                                                            <div className="email-mobile flex flex-col">
                                                                                <div>{application?.mobile_no}</div>
                                                                                <div>{application?.landline_no}</div>
                                                                                <div>{application?.whatsapp_no}</div>
                                                                            </div>
                                                                        </td>
                                                                        <td>{application?.visatype?.country?.icon} {application?.visatype?.description} </td>
                                                                        <td>{application?.intake?.intake}</td>
                                                                        <td>{counselorName(application?.assignUser?.CounselorAssign?.userdetail)}</td>
                                                                        <td>{application?.registered_date ? <span className="registered">{moment(application?.registered_date).format('DD-MM-YYYY')}</span> : '-'}</td>
                                                                        <td><span className="visaapproved">{moment(item?.createdAt).format('DD-MM-YYYY')}</span></td>
                                                                        <td>{application?.status_of_prospect?.name}</td>
                                                                        <td>{application?.refferal?.refferal}</td>
                                                                        <td>
                                                                            <div className="w-[40px]">
                                                                                <ApplicantViewButton item={application} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan="14" className={active.index === index && active.status ? "dropdown-table-wrpr active" : "dropdown-table-wrpr"}>
                                                                            <table className="table dropdown-table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Prioriy</th>
                                                                                        <th>College / University</th>
                                                                                        <th>Program Name</th>
                                                                                        <th>Intake</th>
                                                                                        <th>Application Status</th>
                                                                                        <th>Duration</th>
                                                                                        <th>Code</th>
                                                                                        <th>Status</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td width="15">
                                                                                            <div
                                                                                            className='font-[600] flex items-center justify-center w-[25px] h-[25px] rounded-full text-white'
                                                                                            style={{background: program?.status === "CONFIRMED" ? 'green' : 'orange'}}>
                                                                                                {program?.priority}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>{program?.college?.name}</td>
                                                                                        <td>
                                                                                            {program?.program?.programlink ?
                                                                                            <a className='text-blue-700' href={program?.program?.programlink} target='_blank'>
                                                                                                {program?.program?.name}
                                                                                            </a>:
                                                                                            <div>{program?.program?.name}</div>
                                                                                            }
                                                                                        </td>
                                                                                        <td>{program?.intake?.intake}</td>
                                                                                        <td>
                                                                                            <ApplicationStatusModal item={program} />
                                                                                        </td>
                                                                                        <td>{program?.program?.duration && `${program?.program?.duration} Year`}</td>
                                                                                        <td>{program?.program?.program_code}</td>
                                                                                        <td>{program?.status}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>
                                                            )}
                                                        )}
                                                    </tbody>
                                                </table>
                                                <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default VisaApprovedReport;