import * as yup from 'yup'

export const generateValidationSchema = (inputFields) => {
    const validationSchema = inputFields?.reduce((accumulator, field) => {
    const { name, label, required, min, max } = field;
  
    let fieldValidator = yup.string().label(label).nullable()
  
    if (required) {
        fieldValidator = fieldValidator.required(`${label} is required`);
    }
    if (min !== undefined) {
        fieldValidator = fieldValidator.min(min, `Minimum length is ${min} characters`);
    }
    if (max !== undefined) {
        fieldValidator = fieldValidator.max(max, `Maximum length is ${max} characters`);
    }
    if (name === "email") {
        fieldValidator = fieldValidator.email("Enter valid email");
    }
    if(name==="mobile"){
        fieldValidator = fieldValidator.matches(
            /^(?:\+88|01)?\d{10}$/,
            'Enter valid 10-digit mobile number'
        );
    }

    if (field.array) {
        fieldValidator = yup.array().of(fieldValidator);
    }

    const keys = name.split(".");
    if (keys.length > 1) {

        const [parentKey, childKey] = keys;

        if (!accumulator[parentKey]) {
            accumulator[parentKey] = yup.object();
        }

        accumulator[parentKey] = accumulator[parentKey].shape({
            [childKey]: fieldValidator,
        });

        return accumulator;
    }

    accumulator[name] = fieldValidator;
    return accumulator;

    }, {});
  
    return yup.object().shape(validationSchema);
};