import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { IconPlaneDeparture } from "@tabler/icons-react";
import LoaderCommon from "../../../Common/LoaderCommon";
import LeadStatus from "../../../Common/LeadStatus";
import PaginationComponent from "../../../Common/PaginationComponent";
import AdvancedSearch from "../../../Common/AdvancedSearch";
import ApplicantViewButton from "../../../Common/ApplicantViewButton";
import reportQueries from "../../../../queries/reportQueries";
import { useAdvancedSearch } from "../../../../store/advancedSearchStore";
import { usePaginationStore } from "../../../../store/userStore";
import FollowUp from "../../../Common/FollowUp";


const ApplicationManagementAndStatusReport =()=>{

    const [result, setResult] = useState([]);

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const data = useAdvancedSearch((state) => state);

    const visaReport = reportQueries.useApplicationManagementAndStatusReport(
        (response) => {
            setResult(response);   
        }
    );

    useEffect(()=>{
        visaReport.mutateAsync({
            page: currentPage,
            size: 25,
            ...data,
        });
    },[data, currentPage]);

    const counselorName =(counselor)=>{
        if(!counselor){
            return 'N/A'
        }
        return `${counselor?.firstname} ${counselor?.lastname}`
    }

    const dueDateCalculation = (item) =>{
        if(item.length > 0){
            const firstFee = item[0]
            const totalAmount = firstFee?.amount
            console.log(firstFee)
            const paidFee = firstFee?.feepaids?.reduce((accumulator, item) => {
                return accumulator + parseFloat(item.amount || 0);
            }, 0);
            const dueAmount = totalAmount - paidFee
            if(dueAmount > 0){
                return moment(firstFee?.due_date).format("DD-MM-YYYY")
            }else{
                return
            }
        }
    }

    return(
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Application Management and Status Report</li>
                </ol>
                </nav>
            </div>
            
            <div className="add_clients">
                <div className="flex items-center gap-[4px] mb-4">
                    <i className="followup-icon">
                        <IconPlaneDeparture size="25" className='text-[#7D6FAB]' />
                    </i>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                            <span className="text-[16px] font-medium">Application Management and Status Report</span>	
                        </div>
                    </div>
                </div>
                <div id="accordion" className="add_clients_accordion_form mt-[10px]">
                    <div  className="card" id="appointments_wrpr_employee">
                        <div className="card-header">
                            Application Management and Status Report
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">

                            <AdvancedSearch appmanagementreport />

                            <div className="search-results-count-wrpr">
                                {
                                result?.data?.totalItems > 0 ?
                                `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                `O results found`
                                }
                            </div>

                            {visaReport.isLoading ?
                                <LoaderCommon />:
                                <div className="table-wrpr">
                                    <div className="table-section">
                                        <div className="row">
                                            <div className="col-md-12">                                   
                                                <table border={1} className="table prospect-table">
                                                    <thead>
                                                        <tr className="!font-bold">
                                                            <th width="30">#</th>
                                                            <th>Profile Status</th>
                                                            <th>Office</th>
                                                            <th>Applicant Name</th>
                                                            <th>Contact Number</th>
                                                            <th>Visa Type</th>
                                                            <th>Intake</th>
                                                            <th>Counselor</th>
                                                            <th>Prospect Status</th>
                                                            <th>Application Status</th>
                                                            <th>University name</th>
                                                            <th>App. submitted on</th>
                                                            <th>Offer due date</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody  className="ui-sortable">
                                                        {   
                                                            result?.data?.items?.map((item, index) =>{
                                                            const application = item?.application
                                                            return(
                                                                <tr className="ui-sortable-handle">
                                                                    <td>{(index + 1) + (result?.data?.currentPage * 25)}</td>
                                                                    <td><LeadStatus item={application}/></td>
                                                                    <td>{application?.office?.name}</td>
                                                                    <td>{application?.applicant_name} {application?.middlename} {application?.lastname}</td>
                                                                    <td>
                                                                        <div className="email-mobile flex flex-col">
                                                                            <div>{application?.mobile_no}</div>
                                                                            <div>{application?.landline_no}</div>
                                                                            <div>{application?.whatsapp_no}</div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{application?.visatype?.country?.icon} {application?.visatype?.description} </td>
                                                                    <td>{application?.intake?.intake}</td>
                                                                    <td>{counselorName(application?.assignUser?.CounselorAssign?.userdetail)}</td>
                                                                    <td>{application?.status_of_prospect?.name}</td>
                                                                    <td>{item?.appstaffstatuss?.name}</td>
                                                                    <td>{item?.college?.name}</td>
                                                                    <td>{item?.statusAt ? moment(item?.statusAt).format("DD-MM-YYYY") : "-"}</td>
                                                                    <td>{dueDateCalculation(item?.admission_feetypes)}</td>
                                                                    <td className="flex flex-col gap-1">
                                                                        <div className="w-[120px]">
                                                                            <FollowUp item={application} />
                                                                        </div>
                                                                        <div className="w-[120px]">
                                                                            <ApplicantViewButton item={application} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        )}
                                                    </tbody>
                                                </table>
                                                <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ApplicationManagementAndStatusReport;