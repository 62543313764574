import { useMutation } from "react-query";
import visatypeCountService from "../services/visatypeCountService";

const useNotinterestedOrQualified = (onData) => {
  return useMutation(visatypeCountService.notInterestedOrQualified, {
    onSuccess: (data) => onData(data),
  })
}

const useMyClientsCount = (onData) => {
  return useMutation(visatypeCountService.myClientsCount, {
    onSuccess: (data) => onData(data),
  })
}

const visatypeCountQueries = {
    useNotinterestedOrQualified,
    useMyClientsCount
}

export default visatypeCountQueries