import { Modal } from '@nextui-org/react'
import React, { Fragment, useState } from 'react'
import visatypeCountQueries from '../../queries/visatypeCountQueries'
import LoaderCommon from '../Common/LoaderCommon'
import { userStore } from '../../store/userStore'

const MyClientsCount = ({data, label}) => {
    
    const [open, setOpen] = useState(false)
    const [result, setResult] = useState([])
    const [loader, setLoader] = useState(false)
    const user = userStore(state => state.user)

    const handleOpen =()=> {
        setLoader(true)
        setOpen(true)
        getData.mutateAsync({...data, role: user?.role})
    }

    const handleClose =()=> {
        setOpen(false)
        setLoader(true)
    }

    const getData = visatypeCountQueries.useMyClientsCount(
        (res)=>{
            setLoader(false)
            setResult(res?.data)
        }
    )

  return (
    <Fragment>
        <button 
        onClick={handleOpen}
        className='btn-customized-large'
        >My client's consolidated view</button>
        <Modal
        scroll
        fullScreen
        open={open}
        onClose={handleClose}
        closeButton
        >
            <Modal.Header>
                <div className='text-[18px] font-medium'>
                    My client’s consolidated view 
                </div>
            </Modal.Header>
            <Modal.Body>

                {
                    loader ? <LoaderCommon /> :
                    <table className='table-dotted w-full'>
                        <thead>
                            <tr className='table-row'>
                                <th>Visa Type</th>
                                <th className='text-center'>Active Non Reg Files</th>
                                <th className='text-center'>On Hold Non Reg</th>
                                <th className='text-center'>Long Term Non Reg</th>
                                <th className='text-center'>Active Reg Files</th>
                                <th className='text-center'>Awaiting Offer</th>
                                <th className='text-center'>Conditional</th>
                                <th className='text-center'>UnConditional</th>
                                <th className='text-center'>On Hold Reg</th>
                                <th className='text-center'>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {result?.map((item, index) => {
                                const indexOdd = index % 2 === 0
                                return(
                                    <tr key={index} className={`table-row ${indexOdd ? 'bg-[#9a9bee]' : ''}`}>
                                        <td>{item?.name}</td>
                                        <td className='py-3 text-center'>{item?.activeNonRegistered}</td>
                                        <td className='py-3 text-center'>{item?.onHoldNonRegistered}</td>
                                        <td className='py-3 text-center'>{item?.longTermNonRegistered}</td>
                                        <td className='py-3 text-center'>{item?.activeRegistered}</td>
                                        <td className='py-3 text-center'>{item?.awaiting}</td>
                                        <td className='py-3 text-center'>{item?.unconditional}</td>
                                        <td className='py-3 text-center'>{item?.conditional}</td>
                                        <td className='py-3 text-center'>{item?.onHoldRegistered}</td>
                                        <td className='py-3 text-center'>{item?.total}</td>
                                    </tr>
                                )}
                            )}
                            <tr className='table-row bg-[#ffb172]'>
                                <td>TOTAL</td>
                                <td className='py-3 text-center !text-[16px]'>{result?.reduce((a, b) => a + b.activeNonRegistered, 0)}</td>
                                <td className='py-3 text-center !text-[16px]'>{result?.reduce((a, b) => a + b.onHoldNonRegistered, 0)}</td>
                                <td className='py-3 text-center !text-[16px]'>{result?.reduce((a, b) => a + b.longTermNonRegistered, 0)}</td>
                                <td className='py-3 text-center !text-[16px]'>{result?.reduce((a, b) => a + b.activeRegistered, 0)}</td>
                                <td className='py-3 text-center !text-[16px]'>{result?.reduce((a, b) => a + b.awaiting, 0)}</td>
                                <td className='py-3 text-center !text-[16px]'>{result?.reduce((a, b) => a + b.unconditional, 0)}</td>
                                <td className='py-3 text-center !text-[16px]'>{result?.reduce((a, b) => a + b.conditional, 0)}</td>
                                <td className='py-3 text-center !text-[16px]'>{result?.reduce((a, b) => a + b.onHoldRegistered, 0)}</td>
                                <td className='py-3 text-center !text-[16px]'>{result?.reduce((a, b) => a + b.total, 0)}</td>
                            </tr>
                        </tbody>
                    </table>
                }

            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default MyClientsCount
