import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import DropDown from "../../Common/DropDown";
import moment from "moment";
import { Checkbox, Loading } from "@nextui-org/react";
import RegisteredCheckSearch from "../../Common/RegisteredCheckSearch";
import SelectSearch from "../../Common/SelectSearch";
import InputSearch from "../../Common/InputSearch";
import LoaderCommon from "../../Common/LoaderCommon";
import { GanttChartSquare } from "lucide-react";
import LeadStatus from "../../Common/LeadStatus";
import { useLoaderStore, usePaginationStore } from "../../../store/userStore";
import PaginationComponent from "../../Common/PaginationComponent";
import AdvancedSearch from "../../Common/AdvancedSearch";
import { useAdvancedSearch } from "../../../store/advancedSearchStore";


const CeoNotInterestedReport =()=>{

    const [result, setResult] = useState([]);

    const data = useAdvancedSearch((state) => state);
    const financialYear = useAdvancedSearch((state) => state.financialYear);
    const setFinancialYear = useAdvancedSearch((state) => state.setFinancialYear);
    const loader = useLoaderStore((state) => state.loader);
    const setLoader = useLoaderStore((state) => state.setLoader);
    const resetPage = usePaginationStore((state) => state.resetPage);
    const resetAll = useAdvancedSearch((state)=> state.resetAll)

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
    
    const managerReport = authQueries.useCEONotInterestedMutation(
        (response) => {
            setResult(response);   
            setLoader(false)     
        }
    );

    useEffect(()=>{
        managerReport.mutateAsync({
            page: currentPage,
            size: 100,
            ...data
        });
    },[data, currentPage]);

    const counselorName =(counselor)=>{
        if(!counselor){
            return 'N/A'
        }
        return `${counselor?.firstname} ${counselor?.lastname}`
    }

    return(
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Not Interested clients</li>
                    </ol>
                </nav>
            </div>
            
            <div className="add_clients">
                <div className="flex items-center gap-[4px] mb-4">
                    <i className="followup-icon">
                        <GanttChartSquare size="25" className='text-[#7D6FAB]' />
                    </i>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                            <span className="text-[16px] font-medium">Not Interested Report</span>	
                        </div>
                    </div>
                </div>
                <div id="accordion" className="add_clients_accordion_form mt-[10px]">
                    <div  className="card" id="appointments_wrpr_employee">
                        <div className="card-header">
                            Not Interested Report
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                
                                <AdvancedSearch />

                                <div className="search-results-count-wrpr">
                                    {
                                    result?.data?.totalItems > 0 ?
                                    `Search Results ${result?.data?.currentPage * 100 + 1}-${result?.data?.currentPage * 100 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                    `O results found`
                                    }
                                </div>

                                {managerReport.isLoading ?
                                    <LoaderCommon />:
                                    <div className="table-wrpr">
                                        <div className="table-section">
                                            <div className="row">
                                                <div className="col-md-12">                                   
                                                    <table border={1} className="table prospect-table">
                                                        <thead>
                                                            <tr className="!font-bold">
                                                                <th width="30">#</th>
                                                                <th>Profile Status</th>
                                                                <th>Office</th>
                                                                <th>Date Created</th>
                                                                <th>Applicant Name</th>
                                                                <th>Contact Number</th>
                                                                <th>Visa Type</th>
                                                                <th>Intake</th>
                                                                <th>Counselor</th>
                                                                <th>Reg. Date</th>
                                                                <th>Not Interested Date</th>
                                                                <th>Prospect Status</th>
                                                                <th>Referred By</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody  className="ui-sortable">
                                                            {   
                                                                result?.data?.items?.map((item, index) =>(
                                                                    <tr className="ui-sortable-handle">
                                                                        <td>{(index + 1) + (result?.data?.currentPage * 100)}</td>
                                                                        <td><LeadStatus item={item} /></td>
                                                                        <td>{item?.office?.name}</td>
                                                                        <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                        <td>{item?.applicant_name} {item?.middlename} {item?.lastname}</td>
                                                                        <td>
                                                                            <div className="email-mobile flex flex-col">
                                                                                <div>{item.mobile_no}</div>
                                                                                <div>{item.landline_no}</div>
                                                                                <div>{item.whatsapp_no}</div>
                                                                            </div>
                                                                        </td>
                                                                        <td>{item?.visatype?.country?.icon} {item?.visatype?.description} </td>
                                                                        <td>{item?.intake?.intake}</td>
                                                                        <td>{counselorName(item?.assignUser?.CounselorAssign?.userdetail)}</td>
                                                                        <td>{item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}</td>
                                                                        <td>
                                                                            {item?.applicant_prospects_statuses?.[0]?.createdAt &&
                                                                            <span className="notinterested">
                                                                                {moment(item?.applicant_prospects_statuses?.[0]?.createdAt).format('DD-MM-YYYY')}
                                                                            </span>}
                                                                        </td>
                                                                        <td>{item?.status_of_prospect?.name}</td>
                                                                        <td>{item?.refferal?.refferal}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CeoNotInterestedReport;