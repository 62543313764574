import { yupResolver } from '@hookform/resolvers/yup'
import React,{useState, useEffect, Fragment} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import authQueries from '../../queries/authQueries'
import * as yup from 'yup'
import { message as messages } from 'antd'
import { Checkbox, Switch } from '@nextui-org/react'
import { userStore } from '../../store/userStore'
import moment from 'moment'
import {AutoCompleteAntd, AutoCompleteAntdOffice, AutoCompleteChild, AutoCompleteChilds, AutoCompleteIntake}  from '../Clients/AddClients/AutoComplete'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import { Autocomplete, TextField } from '@mui/material'
import LoaderCommon from '../Common/LoaderCommon'


const TeleCallerClientAdd = ({lead_status}) => {

    const appStore = userStore();

    const [resultVisa, setResultVisa] = useState([]);
    const [intakeResult, setIntakeResult] = useState([]);
    const [refResult, setRefResult] = useState([]);
    const [officeResult, setOfficeResult] = useState([]);
    const [qualifyResult, setQualifyResult] = useState([]);
    const [childAge, setChildAge] = useState(0);
    const [agee, setAgee] = useState(null); 
    const [spouseData, setSpouseData] = useState(false)
    const [office, setOffice] = useState(null)

    const [name, setName] = useState('');
    const [mName, setMName] = useState('')
    const [lName, setLName] = useState('')
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [today, setToday] = useState(moment().format('YYYY-MM-DD'));
    const [ageeSpouse,setAgeeSpouse] = useState(null);
    const [visaid, setVisaId] = useState(null);
    const [image, setImage] = useState(appStore?.appAllData?.photo)
    const [status, setStatus] = useState([]);
    const [loader, setLoader] = useState(true)
    const [emailExist, setEmailExist] = useState('')
    const [mobileExist, setMobileExist] = useState('')

    const [visaData, setVisaData] = useState('');
    const [intakeData, setIntakeData] = useState('');
    const [refferData, setRefferData] = useState('');
    const [officeData, setOfficeData] = useState('');
    const [qualData, setQualData] = useState('');
    const [spoQualData, setSpoQualData] = useState('');
    const [follow, setFollow] = useState(false)
    const [visaidErr, setVisaIdErr] = useState(false)

    const [users, setUsers] = useState({loan: 'NO', interview: 'NO', travel: 'NO', gic: 'NO'})

    const visaType = authQueries.useVisaTypeMutationList(
        (response) => {
            // let data = response?.data.find(v => v.id === appStore.appData.visatype)
            setVisaData(appStore?.appAllData?.visa_type_id)
            let dataArray = []
            for(let datas of response?.data?.items){
                const dataValues = {
                    label:datas?.description,
                    value:datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }
            setResultVisa(dataArray)
        }
    );


    const inTake = authQueries.useIntakeVisaMutationList(
        (response) => {
            let data = response?.data?.find(v => v.id === appStore?.appAllData?.intake_id)
            if(data){
                setIntakeData(data?.id) 
                setValue('intake_id', data?.id)
            }else{
                for(let datas of response?.data){
    
                    if(datas?.active_status === "ACTIVE"){
                        setIntakeData(datas?.id) 
                        setValue('intake_id', datas?.id)
                    }
    
                }
            }
            let dataArray = []
            for(let datas of response?.data){
                const dataValues = {
                    label:datas?.intake,
                    value:datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }
            setIntakeResult(dataArray)
        }

    );

    const Refferal = authQueries.useRefferalMutationList(
        (response) => {
            let data = response?.data?.items.find(v => v.id === appStore?.appAllData?.referral_id)
            setRefferData({label:data?.refferal, value: data?.id})
            let dataArray = []
            for(let datas of response?.data?.items){
                const dataValues = {
                    label:datas?.refferal,
                    value:datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }
            setRefResult(dataArray)
        }
    );

    const Office = authQueries.useOfficeMutationList(
        (response) => {
            let data = response?.data.items?.find(v => v.id === appStore?.appAllData?.office_id)
            setOfficeData({label:data?.name, value: data?.id})
            console.log(data)
            let dataArray = []
            for(let datas of response?.data?.items){
                const dataValues = {
                    label:datas?.name,
                    value:datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }
            setOfficeResult(dataArray)
        }
    );

    const Qualify = authQueries.useQualificationMutationList(

        (response) => {
            setQualifyResult(response)
            let applicantData = response?.data.items?.find(v =>  v.id === appStore?.appAllData?.qid)
            let spoData = response?.data?.items?.find(v => v.id === appStore?.appAllData?.spouse?.qid)
            setQualData(applicantData?.id)
            setSpoQualData({label:spoData?.name, value: spoData?.id})
            let dataArray = []
            for(let datas of response?.data?.items){
                const dataValues = {
                    label:datas?.name,
                    value:datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }
            setQualifyResult(dataArray)

        }
    );

    const districtList = authQueries.useDistrictMutationList(
        (response) => {
            setLoader(false)
        }
    );
  
    const masterCall = async ()=> {
        await reset({...appStore.appAllData})
        setSpouseData(appStore?.appAllData?.spouse_status === "TRUE" ? true: false)
        setChildAge(appStore?.appAllData?.no_of_children ? appStore?.appAllData?.no_of_children : 0)
        setUsers({loan: appStore.appAllData.loan, interview: appStore.appAllData.interview, travel: appStore.appAllData.travel, gic: appStore.appAllData.gic})
        await visaType.mutateAsync({search: '', status: '', page:0, size:500});
        await Refferal.mutateAsync({search: '', status: '', page:0, size:500});
        await Office.mutateAsync({search: '', status: '', page:0, size:500});
        await Qualify.mutateAsync({search: '', status: '', page:0, size:500});
        await districtList.mutateAsync({size: 2000})
    }

    useEffect(()=>{  
        masterCall();
    },[])

    useEffect(()=> {
        inTake.mutateAsync(visaid ? visaid : appStore.appAllData.visa_type_id);
    }, [visaid])

    const userList = authQueries.useCounselorAssignList(
        (res) => {
        }
    )

    useEffect(()=> {
        userList.mutateAsync({ role: 3, office: office })
    },[office])

    useEffect(()=>{
        statusData.mutateAsync({role: appStore.user.rid, visa: visaid});
    },[visaid])


    const statusData = authQueries.useStatusOfProspectsRoleList(
        (response) => {
            setStatus(response)            
        }   
    );


    const applicantSchema = yup.object({
        visa_type_id: yup
            .string('⚠ Please enter visa type')
            .required('⚠ Visa type is required'),
        intake_id: yup
            .string('⚠ Please enter intake')
            .required('⚠ Intake is required'),
        office_id: yup
            .string('⚠ Please enter office')
            .required('⚠ Office is required'),
        referral_id: yup
            .string('⚠ Please enter reffered')
            .required('⚠ Reffered is required'),
        applicant_name: yup
            .string('⚠ Please enter applicant firstname')
            .required('⚠ Applicant firstname is required'),
        lastname: yup
            .string('⚠ Please enter applicant lastname')
            .required('⚠ Applicant lastname is required'),
        email: yup
            .string('⚠ Please enter applicant email')
            .required('⚠ Applicant email is required'),
        mobile_no: yup
            .string('⚠ Please enter applicant mobile')
            .required('⚠ Applicant mobile is required'),
        comment: yup
            .string('⚠ Please enter Prospect comment')
            .required('⚠ Prospect comment is required'),
    });
    

    const applicantSchemaUpdate = yup.object({
        visa_type_id: yup
            .string('⚠ Please enter visa type')
            .required('⚠ Visa type is required'),
        intake_id: yup
            .string('⚠ Please enter intake')
            .required('⚠ Intake is required'),
        office_id: yup
            .string('⚠ Please enter office')
            .required('⚠ Office is required'),
        referral_id: yup
            .string('⚠ Please enter reffered')
            .required('⚠ Reffered is required'),
        applicant_name: yup
            .string('⚠ Please enter applicant name')
            .required('⚠ Applicant name is required'),
        email: yup
            .string('⚠ Please enter applicant email')
            .required('⚠ Applicant email is required'),
        mobile_no: yup
            .string('⚠ Please enter applicant mobile')
            .required('⚠ Applicant mobile is required'),
    });

    const spouseSchema = yup.object({
        visa_type_id: yup
            .string('⚠ Please enter visa type')
            .required('⚠ Visa type is required'),
        intake_id: yup
            .string('⚠ Please enter intake')
            .required('⚠ Intake is required'),
        office_id: yup
            .string('⚠ Please enter office')
            .required('⚠ Office is required'),
        referral_id: yup
            .string('⚠ Please enter reffered')
            .required('⚠ Reffered is required'),
        applicant_name: yup
            .string('⚠ Please enter applicant name')
            .required('⚠ Applicant name is required'),
        email: yup
            .string('⚠ Please enter applicant email')
            .required('⚠ Applicant email is required'),
        mobile_no: yup
            .string('⚠ Please enter applicant mobile')
            .required('⚠ Applicant mobile is required'),
        spouse: yup.object().shape({
            spouse_name: yup
                .string('⚠ Please enter spouse name')
                .required('⚠ Spouse name is required'),
        }),
        comment: yup
            .string('⚠ Please enter Prospect comment')
            .required('⚠ Prospect comment is required'),
    });

    const spouseSchemaUpdate = yup.object({
        visa_type_id: yup
            .string('⚠ Please enter visa type')
            .required('⚠ Visa type is required'),
        intake_id: yup
            .string('⚠ Please enter intake')
            .required('⚠ Intake is required'),
        office_id: yup
            .string('⚠ Please enter office')
            .required('⚠ Office is required'),
        referral_id: yup
            .string('⚠ Please enter reffered')
            .required('⚠ Reffered is required'),
        applicant_name: yup
            .string('⚠ Please enter applicant name')
            .required('⚠ Applicant name is required'),
        email: yup
            .string('⚠ Please enter applicant email')
            .required('⚠ Applicant email is required'),
        mobile_no: yup
            .string('⚠ Please enter applicant mobile')
            .required('⚠ Applicant mobile is required'),
        spouse: yup.object().shape({
            spouse_name: yup
                .string('⚠ Please enter spouse name')
                .required('⚠ Spouse name is required'),
        }),
    });

      
    const navigate = useNavigate();


    const { register, reset, control, handleSubmit, formState:{ errors }, setValue, getValues, setError} = useForm({
         resolver: yupResolver(spouseData?spouseSchema:applicantSchema)
    });

    const addApplication = authQueries.useApplicantMutationAdd(
        (response) => {
            if(response?.message === "Success"){ 
                deleteApplication.mutateAsync(appStore?.appAllData?.id)
                if(appStore.user.role === "TELECALLER"){
                    navigate('/import')
                }
                messages.success("successfully created")
            }
        }   
    );

    const onSubmit = (data) => {

        if(visaid === null){
            setVisaIdErr(true)
        }else{
            setVisaIdErr(false)
        }
        data.lead_status = lead_status
        data.spouse_status = spouseData ? "TRUE" : "FALSE"
        data.photo = image
        data.follow = follow
        data.loan = users?.loan
        data.travel = users?.travel
        addApplication.mutateAsync(data);

    }

    const deleteApplication = authQueries.useImportApplicantMutationDelete(
        (response) => {
        }
    );

	const changeBirthHandler =(e)=> {
		const a = moment(e.target.value);
		const b = moment(today);
		var years = a.diff(b, 'year');
		b.add(years, 'years');

		const noOfDaysInb = b.daysInMonth();
		const noOfDaysIna = a.daysInMonth();
		let months = 0;
		if (noOfDaysInb > noOfDaysIna) {
			months = b.diff(a, "months");
			a.add(months, "months");
		} else {
			months = a.diff(b, 'months');
			b.add(months, 'months');
		}
		var days = a.diff(b, 'days');

		var totalYears = Math.abs(years);
		var totalMonths = Math.abs(months);
		var totalDays = Math.abs(days);

		if (totalMonths == 0 && totalDays == 0 && totalYears > 0) {
			return `Happy Birthday! 🎉 You're ${totalYears} years old!`;
		}

        setAgee(totalYears)
        setValue('age', totalYears)

		return totalYears
	}

    const changeBirthHandlerSpouse =(e)=> {
		const a = moment(e.target.value);
		const b = moment(today);
		var years = a.diff(b, 'year');
		b.add(years, 'years');

		const noOfDaysInb = b.daysInMonth();
		const noOfDaysIna = a.daysInMonth();
		let months = 0;
		if (noOfDaysInb > noOfDaysIna) {
			months = b.diff(a, "months");
			a.add(months, "months");
		} else {
			months = a.diff(b, 'months');
			b.add(months, 'months');
		}
		var days = a.diff(b, 'days');

		var totalYears = Math.abs(years);
		var totalMonths = Math.abs(months);
		var totalDays = Math.abs(days);

		if (totalMonths == 0 && totalDays == 0 && totalYears > 0) {
			return `Happy Birthday! 🎉 You're ${totalYears} years old!`;
		}


        setAgeeSpouse(totalYears)
        setValue('spouse.age', totalYears)
		return totalYears
	}

    const emailValidation =({email, visa})=>{
        if(email){
            emailValidApplicant.mutateAsync({email: email, visa:visa, appId:appStore.appid?appStore.appid:null})
        }
    }

    const emailValidApplicant = authQueries.useEmailValidApplicant(
        (response) => {
            if(response?.message === "Email already exist"){
                setEmailExist("Email already exist")
            }else{
                setEmailExist('')
            }
        }   
    );

    const mobileValidation =({mobile, visa})=>{
        if(mobile){
            mobileValidApplicant.mutateAsync({mobile: mobile, visa:visa, appId:appStore.appid?appStore.appid:null})
        }
    }

    const mobileValidApplicant = authQueries.useMobileValidApplicant(
        (response) => {
            if(response?.message === "Mobile already exist"){
                setMobileExist("Mobile number already exist")
            }else{
                setMobileExist('')
            }
        }   
    );


    const handleChange =(e)=>{
        const data = new FormData();
        data.append('file', e)
        imageUploadCreate.mutateAsync(data)
    }


    const imageUploadCreate = authQueries.useFileUploadCommon(
        (response) => {
            setImage(response?.file?.filename)
        }   
    );


  return (

    <div>

    {loader ? 
            <LoaderCommon /> :
        <Fragment>
                <div className="card">
                    <div className="card-header">
                        <a className="card-link" data-toggle="collapse" href="#applicant-details">
                            Applicant Details -
                            <span className="applicant-details-wrpr">
                                <span>{name?<><i className="icon-user"></i>&nbsp;{name}</>:<><i className="icon-user"></i>&nbsp;{appStore?.appAllData?.applicant_name}</>}&nbsp;
                                {mName?mName:appStore?.appAllData?.middlename}&nbsp;
                                {lName?lName:appStore?.appAllData?.lastname}</span>
                                {email?<em ><i className="icon-envelope3"></i> {email}</em>:<span><i className="icon-envelope3"></i> {appStore?.appAllData?.email}</span>}
                                {mobile?<em href="#"><i className="icon-whatsapp"></i>&nbsp;{mobile}</em>:<span><i className="icon-whatsapp"></i> + {appStore?.appData?.whatsapp_no}</span>}
                            </span>
                        </a>
                        {appStore.appId?
                        <div data-fieldspending={"3"} className="tab-info fields-notcompleted">
                            <span className="icon-cancel1"></span>
                        </div>:null
                        }
                    </div>
                    <div id="applicant-details" className="collapse show" data-parent="#accordion">
                    <form  onSubmit={handleSubmit(onSubmit)}>
                        <div className="card-body">
                            <div className="row">
                                <div className='col-md-12' style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                    <div className="form-group include-spouse">
                                        <div className="custom-control custom-switch" style={{display:'flex', gap: 12, alignItems:'center'}}>
                                            <Switch size="sm" onChange={()=>setSpouseData(!spouseData)} checked={spouseData} />
                                            <span>Include spouse Details</span>
                                        </div>
                                    </div>
                                    <div className="profile-wrpr" style={{marginLeft: 0, marginRight: 0}}>
                                        <div id="image-preview" className='profile-img' style={{backgroundSize:"cover", 
                                        backgroundImage:image?
                                        `url(${process.env.REACT_APP_BASE_URL}/api/fileuploadcommon/${image})`:`url(${require('../../assets/images/profile-avatar.jpg')})`
                                        }}>
                                            <label htmlFor="image-upload" id="image-label">Choose File</label>
                                            <input onChange={(e)=>{handleChange(e.target.files[0])}} type="file" name="image" id="image-upload" /> 
                                        </div>
                                    </div>
                                </div>
                            <div className="col-md-3">
                                <div>
                                    <label htmlFor="typevisa">Type of Visa:<em className="mandatory">*</em></label>
                                        <AutoCompleteChilds
                                            control={control} 
                                            data={resultVisa} 
                                            name={"visa_type_id"} 
                                            value={visaData} 
                                            emailValid={emailValidation} 
                                            mobileValid={mobileValidation}
                                            email={email}
                                            mobile={mobile}
                                            setVisaId={setVisaId}
                                            error={errors.visa_type_id}
                                        />
                                        <div className='error-msg'>{errors.visa_type_id?.message}</div>
                                    </div>
                            </div>
                            <div className="col-md-3">
                                <div>
                                    <label htmlFor="intake">Intake:<em className="mandatory">*</em></label>
                                    <AutoCompleteAntd 
                                        control={control} 
                                        data={intakeResult} 
                                        name={"intake_id"} 
                                        value={intakeData} 
                                        error={errors.intake_id}
                                    />
                                    <div className="error-msg">{errors.intake_id?.message}</div>

                                </div>
                            </div>
                            <div className="col-md-3">
                                <div>
                                    <label htmlFor="reffered-by">Referred By:<em className="mandatory">*</em></label>
                                    <AutoCompleteAntd
                                        dis={appStore?.appAllData?.registered_status === "YES" ? true : false}  
                                        control={control} 
                                        data={refResult} 
                                        name={"referral_id"}
                                        value={refferData} 
                                        error={errors.referral_id}
                                    />
                                    <div className="error-msg">{errors.referral_id?.message}</div>

                                </div>
                            </div>
                            
                            <div className="col-md-3">
                                <div>
                                    <label htmlFor="enrolling-office">Enrolling Office:<em className="mandatory">*</em></label>
                                    <AutoCompleteAntdOffice
                                        setOffice={setOffice}
                                        control={control}
                                        data={officeResult} 
                                        name={"office_id"} 
                                        value={officeData} 
                                        error={errors.office_id}
                                    />
                                    <div className="error-msg">{errors.office_id?.message}</div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className={errors.applicant_name?"form-group error":"form-group"}>
                                    <label htmlFor="applicant_name">First Name:<em className="mandatory">*</em></label>
                                    <input
                                        {...register('applicant_name', {
                                            onChange: (e) => {setName(e.target.value)},
                                            onBlur: (e) => {},
                                        })}
                                        name='applicant_name' 
                                        id="applicant_name" 
                                        type="text" 
                                        // onChange={(e)=>{setName(e.target.value)}} 
                                        className="form-control" /> 
                                    <div className="error-msg">{errors.applicant_name?.message}</div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div>
                                    <label htmlFor="applicant_name">Middle Name</label>
                                    <input
                                        {...register('middlename', {
                                            onChange: (e) => {setMName(e.target.value)},
                                            onBlur: (e) => {},
                                        })}
                                        name='middlename' 
                                        id="applicant_name" 
                                        type="text"
                                        className="form-control" /> 
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className={errors.lastname?"form-group error":"form-group"}>
                                    <label htmlFor="applicant_name">Last Name:<em className="mandatory">*</em></label>
                                    <input
                                        {...register('lastname', {
                                            onChange: (e) => {setLName(e.target.value)},
                                            onBlur: (e) => {},
                                        })}
                                        name='lastname' 
                                        id="applicant_name" 
                                        type="text"
                                        className="form-control" /> 
                                    <div className="error-msg">{errors.lastname?.message}</div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className={errors.marital_status?"form-group error":"form-group"}>
                                    <label htmlFor="marital">Marital:</label>
                                    <select {...register("marital_status")} id="marital" className="form-control" name="marital_status">
                                        <option value="">Select</option>
                                        <option value="SINGLE">Single</option>
                                        <option value="MARRIED">Married</option>
                                        <option value="WIDOWED">Widowed</option>
                                        <option value="DIVORCED">Divorced</option>
                                        <option value="SEPARATED">Separated</option>
                                    </select> 
                                    <div className="error-msg">{errors.marital_status?.message}</div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className={errors.email?"form-group error":"form-group"}>
                                    <label htmlFor="spouse_name">Valid Email Address:<em className="mandatory">*</em></label>
                                    <input 
                                        // {...register("appEmail")} 
                                        // onChange={(e)=>{setEmail(e.target.value)}}  
                                        {...register('email', {
                                            onChange: (e) => {setEmail(e.target.value); emailValidation({email:e.target.value, visa:visaid});},
                                            onBlur: (e) => {},
                                        })}
                                        id="spouse_name" 
                                        type="email" 
                                        className="form-control" 
                                        name="email" />
                                    <div className="error-msg">{errors.email?.message} {emailExist}</div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className={errors.mobile_no?"form-group error":"form-group"}>
                                    <label htmlFor="spouse_name">Mobile Number:<em className="mandatory">*</em></label>
                                        <input
                                        {...register('mobile_no', {
                                            onChange: (e) => {setMobile(e.target.value); mobileValidation({mobile:e.target.value, visa:visaid});},
                                            onBlur: (e) => {},
                                        })}
                                        id="spouse_name" 
                                        type="number" 
                                        className="form-control" 
                                        name="mobile_no" />
                                    <div className="error-msg">{errors.mobile_no?.message} {mobileExist}</div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="spouse_name">Landline No:</label>
                                    <input {...register("landline_no")} id="spouse_name" type="number" className="form-control" name="landline_no" />    
                                </div>
                            </div>
                            
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="spouse_name">Whatsapp Number:</label>
                                    <input {...register("whatsapp_no")} id="spouse_name" type="number" className="form-control" name="whatsapp_no" />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="spouse_name">Pin Code: </label>
                                    <input {...register("pincode")} id="spouse_name" type="number" className="form-control" name="pincode" />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="spouse_name">District</label>
                                    <select {...register("did")} id="spouse_name" type="number" className="form-control" name="did">
                                        <option value="">Select District</option>
                                        {
                                            districtList?.data?.data?.items?.map((item, index) => (
                                                <option value={item.id} key={index} selected={getValues('did') === item?.id?true : false}>{item?.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="spouse_name">Address</label>
                                    <textarea {...register("address")} id="spouse_name" type="number" className="form-control" name="address" />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div>
                                    <label htmlFor="spouse_name">Qualification of Primary Applicant:</label>
                                    <AutoCompleteAntd 
                                        control={control} 
                                        data={qualifyResult} 
                                        name={"qid"} 
                                        value={qualData} 
                                        error={errors.qid}
                                    />
                                    <div className="error-msg">{errors.qid?.message}</div>
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className={errors.occupation?"form-group error":"form-group"}>
                                    <label htmlFor="spouse_name">Current Occupation of Primary Applicant:</label>
                                    <input {...register("occupation")} id="spouse_name" type="text" className="form-control" name="occupation" />
                                    <div className="error-msg">{errors.occupation?.message}</div>
                                </div>
                            </div>

                            <div className="col-md-1">
                                <div>
                                    <label htmlFor="spouse_name">Gender:</label>
                                    <select {...register("gender")} name="gender" className="form-control">
                                        <option value="">Select</option>
                                        <option value="MALE">Male</option>
                                        <option value="FEMALE">Female</option>
                                        <option value="OTHERS">Others</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className={errors.dob?"form-group error":"form-group"}>
                                    <label htmlFor="spouse_name">Date of Birth:</label>
                                    <input 
                                    max={today} 
                                    type="date" 
                                    {...register("dob", {
                                        onChange: (e)=> {changeBirthHandler(e)} 
                                    })} 
                                    name='dob' 
                                    className="form-control applicant-date" 
                                    />                                               
                                    <div className="error-msg">{errors.dob?.message}</div>
                                </div>
                            </div>

                            <div className="col-md-1">
                                <div className={errors.age?"form-group error spouse-toggle-visible":"form-group spouse-toggle-visible"} style={{opacity:0.6}}>
                                    <label htmlFor="spouse_name">Age:</label>
                                    <input {...register("age")} value={agee} disabled style={{fontWeight:900, color:'#000'}} id="spouse_name" type="text" className="form-control" name="age" />
                                    <div className="error-msg">{errors.age?.message}</div>
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="spouse_name">Passport No.:</label>
                                    <input {...register("passport_no")} id="spouse_name" type="text" className="form-control" name='passport_no' />
                                </div>	
                            </div>
                            <div className="col-md-2">
                                <div className={errors.aadhar_no?"form-group error":"form-group"}>
                                    <label htmlFor="spouse_name">Aadhar No.:</label>
                                    <input {...register("aadhar_no")} id="spouse_name" type="text" className="form-control" name='aadhar_no' />
                                    <div className="error-msg">{errors.aadhar_no?.message}</div>
                                </div>	
                            </div>

                            <div className="col-md-3">
                                <div className={spouseData? errors?.spouse?.spouse_name? "form-group error": "form-group spouse-toggle-visible active":"form-group spouse-toggle-visible"}>
                                    <label htmlFor="spouse_name">Full Name of the Spouse:</label>
                                    <input disabled={spouseData?false:true} {...register("spouse.spouse_name")} id="spouse_name" type="text" className="form-control" name="spouse.spouse_name" />
                                    <div className="error-msg">{spouseData?errors?.spouse?.spouse_name?.message:null}</div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className={spouseData? "form-group spouse-toggle-visible active":"form-group spouse-toggle-visible"}>
                                    <label htmlFor="spouse_name">Current Occupation of Spouse:</label>
                                    <input disabled={spouseData?false:true} {...register("spouse.occupation")} id="spouse_name" type="text" className="form-control" name="spouse.occupation"/>  
                                    {/* <div className="error-msg">{errors.spoOccu?.message}</div> */}
                                </div>
                            </div>
                            
                            <div className="col-md-2">
                                <div className={spouseData? errors.spoDob? "form-group error": "form-group spouse-toggle-visible active":"form-group spouse-toggle-visible"}>
                                    <label htmlFor="spouse_name">Spouse Date of Birth:</label>
                                    <input 
                                    disabled={spouseData?false:true} 
                                    max={today} type="date" 
                                    {...register("spouse.dob", {
                                        onChange: (e) => {changeBirthHandlerSpouse(e)} 
                                    })} 
                                    name='spouse.dob' 
                                    className="form-control applicant-date" 
                                    />                                               
                                    <div className="error-msg">{errors.spoDob?.message}</div>
                                </div>
                            </div>

                            <div className="col-md-1">
                                <div className={spouseData? errors.spoAge? "form-group error": "form-group spouse-toggle-visible active":"form-group spouse-toggle-visible"}>
                                    <label htmlFor="spouse_name">Spouse Age:</label>
                                    <input {...register("spouse.age")} value={ageeSpouse} disabled id="spouse_name" type="number" className="form-control" name="spouse.age" />
                                    <div className="error-msg">{errors.spoAge?.message}</div>
                                </div>
                            </div>
                            

                            <div className="col-md-2">
                                <div  className={spouseData? "form-group spouse-toggle-visible active":"form-group spouse-toggle-visible"}>
                                    <label htmlFor="spouse_name">Qualification of Spouse:<em className="mandatory">*</em></label>
                                    <AutoCompleteAntd 
                                        control={control} 
                                        data={qualifyResult} 
                                        name={"spouse.qid"} 
                                        value={spoQualData}
                                        error={errors.spoQualification}
                                    />
                                    <div className="error-msg">{errors.spoQualification?.message}</div>

                                </div>
                            </div>

                                            


                            <div className="col-md-2">
                                <div className={spouseData? errors.spoAadhno? "form-group error": "form-group spouse-toggle-visible active":"form-group spouse-toggle-visible"}>
                                    <label htmlFor="spouse_name">Spouse Aadhar No.:</label>
                                    <input disabled={spouseData?false:true} {...register("spouse.aadhar_no")} id="spouse_name" type="text" className="form-control" name='spouse.aadhar_no' />
                                    <div className="error-msg">{errors.spoAadhno?.message}</div>
                                </div>	
                            </div>
                        
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="spouse_name">Status (If not holding valid Passport):</label>
                                    <select {...register("passport_status")} id="enrolling-office" className="form-control" name='passport_status'>
                                        <option value="NOT">Not applicable</option>
                                        <option value="YES">Applied </option>
                                        <option value="NO">Not Applied</option>
                                    </select>
                                </div>	
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="spouse_name">GeeBee Student ID:</label>
                                    <input style={{fontWeight:900, color:'red'}} {...register('geebee_student_id')} name="geebee_student_id" disabled id="spouse_name" type="text" className="form-control" />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="row">
                                        
                                    <div className="col-md-3">
                                        <div className={spouseData?"form-group spouse-toggle-visible active":"form-group spouse-toggle-visible"}>
                                            <label htmlFor="spouse_name" style={{whiteSpace:'nowrap'}}>No. of Children:</label>
                                            <select {...register("no_of_children")} className="form-control numchildren" onChange={(e)=>{setChildAge(e.target.value)}} name='no_of_children'>
                                                <option>0</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className={childAge>="1" && spouseData?"form-group agegroup age1 showage":"form-group agegroup age1"}>
                                            <label htmlFor="spouse_name">Age:</label>
                                            <input {...register("childrens[0].age")} id="spouse_name" type="text" placeholder="Age" className="form-control" name='childrens[0].age'/>
                                        </div>	
                                    </div>
                                    <div className="col-md-3">
                                        <div className={childAge>="2" && spouseData?"form-group agegroup age2 showage":"form-group agegroup age1"}>
                                            <label htmlFor="spouse_name">Age:</label>
                                            <input {...register("childrens[1].age")} id="spouse_name" type="text" placeholder="Age" className="form-control" name='childrens[1].age'/>
                                        </div>	
                                    </div>
                                    <div className="col-md-3">
                                        <div className={childAge>="3" && spouseData?"form-group agegroup age3 showage":"form-group agegroup age1"}>
                                            <label htmlFor="spouse_name">Age:</label>
                                            <input {...register("childrens[2].age")} id="spouse_name" placeholder="Age" type="text" className="form-control" name='childrens[2].age'/>
                                        </div>	
                                    </div>  
                                </div>
                            </div>

                            {
                                appStore.appid === '' &&
                                <div className="col-md-12 mt-3">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className={errors.pros_status?"form-group error":"form-group"}>
                                                <label htmlFor="applicant_name">Prospect Status:<em className="mandatory">*</em></label>
                                                <select  className="form-control" name='pros_status'  {...register("pros_status")}>
                                                    <option value='1'>Followup Required (Before registration)</option>
                                                </select>
                                                <div className="error-msg">{errors.pros_status?.message}</div> 
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-3">
                                            <div className={errors.comment?"form-group error":"form-group"}>
                                                <label htmlFor="applicant_name">Status Comment:<em className="mandatory">*</em></label>
                                                <textarea  className="form-control" name='comment'  {...register("comment")}>
                                                </textarea>
                                                <div className="error-msg">{errors.comment?.message}</div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* {
                                appStore.appid === '' &&
                                    <Fragment>
                                        <div className='col-md-12'>
                                            <div className='row'>{follow}
                                                <div className='col-md-2'>
                                                    <label for="">&nbsp;</label><br />
                                                    <Checkbox onChange={(e)=>{setFollow(e)}}>
                                                        <span style={{fontSize:'12px', fontWeight:500}}>Set Followup</span>
                                                    </Checkbox>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label for="">Assigned to</label><br />
                                                    <select {...register('assign')} name="assign" className='form-control'>
                                                        {
                                                            appStore?.user?.role === 'TELECALLER' &&
                                                            <option value="">Select Counselor</option>
                                                        }
                                                        {userList?.data?.data?.map((item, index) => (
                                                            appStore?.user?.id === item?.id &&
                                                            <option key={index} value={item?.id}>{item?.firstname} {item?.lastname} </option>
                                                        ))}
                                                        {userList?.data?.data?.map((item, index) => (
                                                            appStore?.user?.id !== item?.id &&
                                                            <option key={index} value={item?.id}>{item?.firstname} {item?.lastname} </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {
                                            follow &&
                                            <div className='col-md-12 mt-3'>
                                                <div className='row'>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label for="">Date:</label><br />
                                                            <input {...register('foldate')} name="foldate" id="arrival_date3" type="date" className="form-control applicant-date" />
                                                            {errors.date && <span className="text-danger">This field is required</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label for="">Time:</label><br />
                                                            <input {...register('foltime')} name="foltime" id="arrival_date3" type="time" className="form-control applicant-date" />
                                                            {errors.time && <span className="text-danger">This field is required</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <div className={errors.folcomment?"form-group error":"form-group"}>
                                                        <label htmlFor="applicant_name">Followup Comment:<em className="mandatory">*</em></label>
                                                        <textarea  className="form-control" name='folcomment'  {...register("folcomment")}>
                                                        </textarea>
                                                        <div className="error-msg">{errors.folcomment?.message}</div> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                            } */}

                            <div className="col-md-12 mt-2">
                                <Checkbox isSelected={users?.loan === 'YES' ?true: false} onChange={(e) => setUsers({...users, loan: e ? 'YES' : 'NO'}) }>
                                    <span style={{fontSize:"12px", fontFamily:400, paddingRight: "12px"}}>Student Requires Loan Assistance</span>
                                </Checkbox>     
                                <Checkbox isSelected={users?.travel === 'YES' ?true: false} onChange={(e) => setUsers({...users, travel: e ? 'YES' : 'NO'}) }>
                                    <span style={{fontSize:"12px", fontFamily:400, paddingRight: "12px"}}>Student Requires Travel Assistance</span>
                                </Checkbox>     
                                {/* <Checkbox checked={users?.gic === 'YES' ?true: false} onChange={(e) => setUsers({...users, gic: e.target.checked ? 'YES' : 'NO'}) }>
                                    <span style={{fontSize:"12px", fontFamily:400, paddingRight: "12px"}}>GIC Executive</span>
                                </Checkbox> */}
                                {/* <Checkbox checked={users?.interview === 'YES' ?true: false} onChange={(e) => setUsers({...users, interview: e.target.checked ? 'YES' : 'NO'}) }>
                                    <span style={{fontSize:"12px", fontFamily:400, paddingRight: "12px"}}>Interview Trainer</span>
                                </Checkbox> */}
                            </div>  

                            <div className="col-md-12 text-center" style={{display:'flex', gap: 12, justifyContent:'center'}}>
                                <button type='button' onClick={()=>{navigate(-1)}} className="btn btn-default btn-back">Go Back</button>
                                <button type='submit' className="btn btn-default btn-save">Save</button>
                            </div>                
                            
                            </div>
                        </div>
                    </form>

                    </div>
                </div>

        </Fragment>
    }
    

    </div>

  )
}

export default TeleCallerClientAdd