import { Loading, Modal } from '@nextui-org/react'
import React, { Fragment, useState } from 'react'
import LoaderCommon from './LoaderCommon'
import authQueries from '../../queries/authQueries'
import { CalendarPlus } from 'lucide-react'
import { message } from 'antd'
import { useApi } from './ApiProvider'
import toast from 'react-hot-toast'
import { IconBuildingSkyscraper, IconCalendarMonth, IconDeviceMobileFilled, IconX } from '@tabler/icons-react'
import { IconCheck } from '@tabler/icons-react'
import { userStore } from '../../store/userStore'
import { IconUserFilled } from '@tabler/icons-react'
import { IconPlane } from '@tabler/icons-react'

const roleItems = [
    {id: 3, label: "Counselor", name: "counselor"},
    {id: 6, label: "Program Coordinator", name: "program"},
    {id: 5, label: "Document Staff", name: "document"},
    {id: 7, label: "Admission Staff", name: "admission"},
    {id: 22, label: "Sop Staff", name: "sop"},
    {id: 9, label: "Fee Coordinator", name: "fee"},
    {id: 8, label: "Visa Staff", name: "visa"},
]

const AssignModal = ({item, roles, selectedRole, label, height, assignToProgram, fontSize, className}) => {

    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState("")
    const [result, setResult] = useState([])
    const [role, setRole] = useState(selectedRole)
    const [selectItem, setSelectItem] = useState("")
    const [office, setOffice] = useState(item?.office?.id)
    const [noteEnable, setNoteEnable] = useState(false)
    const [note, setNote] = useState("")

    const store = userStore()

    const { setApiDataAndNotify } = useApi()

    const counselorList = authQueries.useCounselorAssignList(
        (response) => {
            setResult(response)
        }   
    );

    const officeList = authQueries.useOfficeMutationList(
        (response) => {
        }   
    );

    const handleOpen =()=> {
        setOpen(true)
        officeList.mutateAsync({size: 10000, page:0})
        counselorList.mutateAsync({search:search, role:role, office: office, visa: item?.visa_type_id})
    }

    const handleClose =()=> {
        setApiDataAndNotify(Date)
        setSelectItem("")
        setOpen(false)
        setNoteEnable(false)
    }
    
    const roleSelect =(value)=> {
        const roleValue = parseInt(value)
        setRole(roleValue)
        counselorList.mutateAsync({search:search, role:value, office: office, visa: item?.visa_type_id})
    }

    const searchUser =(value)=> {
        setSearch(value)
        counselorList.mutateAsync({search:value, role:role, office: office, visa: item?.visa_type_id})
    }

    const addAssign = authQueries.useAssignAdd(
        (response) => {
            if(selectedRole === 6){
                if(!response?.reassign){
                    assignToProgram()
                }
            }
            if(response?.message === "Already assign to this counsellor."){
                setNoteEnable(false)
                toast.error("Already assign to this user.")
                return
            }
            setNoteEnable(false)
            setSearch("")
            setSelectItem("")
            setNote("")
            toast.success(`Assigned Successfully`);
        }   
    );

    const assignSubmit =()=>{
        setNoteEnable(true)
    }

    const assigningConfirm = () => {
        const dataValues = {
            applicant_id: item?.id,
            user_id: selectItem,
            role_id: role,
            status: 'ACTIVE',
            office: role === 3 ? office : "",
            note: note
        }
        addAssign.mutateAsync(dataValues);
    }

    const selectOffice =(value)=>{
        setOffice(value)
        counselorList.mutateAsync({search:search, role:role, office: value, visa: item?.visa_type_id})
    }

    const disableButtonCheck =()=>{
        if(store?.user?.role === "RM"){
            if(item?.pros_status === 15 || item?.pros_status === 16 || item?.pros_status === 17 || item?.pros_status === 28 || item?.pros_status === 30){
                return true
            }
        }
        else if(store?.user?.role === "BMANAGER"){
            if(item?.pros_status === 15 || item?.pros_status === 16 || item?.pros_status === 17 || item?.pros_status === 28 || item?.pros_status === 30 || item?.pros_status === 52 || item?.pros_status === 60 ){
                return true
            }
        }
        else{
            return false
        }
    }

    const disabled = disableButtonCheck()

  return (
    <Fragment>

        <button 
        disabled={disabled}
        className={className ? className:'btn-customized'}
        style={{height: height && height, fontSize: fontSize ? fontSize:"10px", opacity: disabled ? "0.5" : "1"}} 
        onClick={handleOpen}
        >
        {label}
        </button>

        <Modal
        open={open}
        closeButton={false}
        onClose={handleClose}
        scroll
        // blur
        width="65%"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        >
            <Modal.Body>
                <div className='mt-2'>
                    <div className="flex justify-between">
                        Assign to {roleItems?.find(element => element.id === parseInt(role))?.label}
                        <button onClick={handleClose} className='text-[12px] text-[#f00]'>Close</button>
                    </div>
                    <div className='flex gap-2 flex-wrap items-center text-[12px] font-semibold mt-3 rounded-[10px]'>
                        <div className="flex gap-2 items-center">
                            <IconUserFilled className='text-[#563A9C]' size={20} />
                            {item?.applicant_name} {item?.middlename} {item?.lastname}
                        </div>
                        <div className='w-[5px] h-[5px] bg-gray-200 rounded-full' /> 
                        <div className="flex gap-2 items-center">
                            <IconDeviceMobileFilled className="text-[#00712D]" size={20} />
                            {item?.mobile_no}
                        </div>
                        <div className='w-[5px] h-[5px] bg-gray-200 rounded-full' /> 
                        <div className="flex gap-2 items-center">
                            <IconBuildingSkyscraper color="#5F6F65" size={20} />
                            {item?.office?.name}
                        </div>
                        <div className='w-[5px] h-[5px] bg-gray-200 rounded-full' /> 
                        <div className="flex gap-2 items-center">
                            <IconPlane color="#FF885B" size={20} />
                            {item?.visatype?.description}
                        </div>
                        <div className='w-[5px] h-[5px] bg-gray-200 rounded-full' /> 
                        <div className="flex gap-2 items-center">
                            <IconCalendarMonth color="#37AFE1" size={20} />
                            {item?.intake?.intake}
                        </div>
                    </div>
                </div>
                {
                    !noteEnable &&
                    <div className='flex justify-end'>
                        <div className="grid grid-cols-4 gap-3 w-3/5 max-[1600px]:w-3/4 max-[1400px]:w-4/5 max-[1200px]:w-full">
                            {role === 22 && <div className='col-span-2' /> }
                            <div className='col-span-1'>
                                <select
                                className='bg-[#f0f0f0] p-[9px] pl-3 w-full text-[13px] rounded-md focus:outline-none'
                                value={role}
                                onChange={(e)=>roleSelect(e.target.value)}
                                type="text"
                                >
                                    {
                                        roleItems?.map((role, index) => {
                                            const exist = roles?.find(element => element === role?.id)
                                            if(!exist){
                                                return
                                            }
                                            return(
                                                <option key={index} value={role?.id}>{role?.label}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            {
                                role !== 22   &&
                                <div className='col-span-2'>
                                    <select
                                    className='bg-[#f0f0f0] p-[9px] pl-3 text-[13px] w-full rounded-md focus:outline-none'
                                    value={office}
                                    onChange={(e)=>{selectOffice(e.target.value)}}
                                    type="text"
                                    >
                                        <option value="">Select Office</option>
                                        {
                                            officeList?.data?.data?.items?.map((off, index) => {
                                                return(
                                                    <option key={index} value={off?.id}>{off?.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            }
                            <div className='col-span-1'>
                                <input
                                className='bg-[#f0f0f0] p-[9px] pl-3 text-[13px] rounded-md focus:outline-none w-full'
                                value={search}
                                onChange={(e)=>{searchUser(e.target.value)}}
                                placeholder="Search User"
                                type="text"
                                />
                            </div>
                        </div>
                    </div>
                }
                {
                    counselorList.isLoading ?
                    <LoaderCommon />:
                    noteEnable ?
                    <textarea
                    onChange={(e)=>{setNote(e.target.value)}}
                    value={note}
                    className="bg-white border border-solid border-gray-300 rounded-md w-full h-10 p-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 min-h-[100px]" 
                    ></textarea>:
                    <div className="grid grid-cols-3 max-xl:grid-cols-2 max-md:grid-cols-1 gap-3 mt-1">
                        {result?.data?.map((user, index) => (  
                            <div
                            key={index}
                            onClick={()=>{setSelectItem(user.id)}}
                            className={`col-span-1 flex ${user.id === selectItem ? 'custom-assign-selected' : "custom-assign-non-selected"} text-white p-[12px] rounded-xl items-center gap-x-3`}
                            >
                                <div className="counsellor-photo-wrpr">
                                    <img src={user?.image? `${user?.image}` : require("../../assets/images/profile-avatar.jpg")} className='w-full h-full' alt="" />
                                </div>
                                <div className="counsellor-name flex flex-col gap-y-1">
                                    <div className='text-[16px]'>{user?.firstname} {user?.lastname}</div>
                                    <div className="flex items-center gap-x-3">
                                        <div className='flex items-center gap-x-1'>
                                            <span className="text-[13px]">Clients</span>
                                            <span className="flex justify-center items-center font-semibold text-[12px] bg-[#f8c207] text-black p-[2px] rounded-lg w-[40px] h-[22px]">{user?.progress}</span> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    result?.data?.length > 0 &&
                    <div className='flex gap-2'>
                        {
                            noteEnable &&
                            <button
                            onClick={()=>{setNoteEnable(false)}}
                            className="flex items-center justify-center rounded-[6px] text-white bg-[red] w-[110px] h-[36px] gap-2">
                                <IconX size="16" />
                                Cancel
                            </button>
                        }
                        {
                            noteEnable?
                            <button
                            disabled={addAssign?.isLoading ? true : false}
                            style={{cursor: !addAssign?.isLoading ? 'pointer' : 'not-allowed'}}
                            onClick={assigningConfirm}
                            className="flex items-center justify-center rounded-[6px] text-white custom-assign-non-selected w-[110px] h-[36px] gap-2">
                            <IconCheck size="16" />
                            Confirm
                            </button>:
                            <button
                            disabled={!selectItem ? true : false}
                            style={{cursor: selectItem ? 'pointer' : 'not-allowed'}}
                            onClick={assignSubmit}
                            className="flex items-center justify-center rounded-[6px] text-white custom-assign-non-selected w-[110px] h-[36px] gap-2">
                            <CalendarPlus size="16" />
                            Assign
                            </button>  
                        }
                    </div>
                }
            </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default AssignModal
