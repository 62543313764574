import React, { Fragment, useEffect, useRef, useState } from 'react'
import authQueries from '../../queries/authQueries'
import LeadStatus from './LeadStatus'
import moment from 'moment'
import FollowUp from './FollowUp'
import { useNavigate } from 'react-router-dom'
import { userStore } from '../../store/userStore'
import ProspectStatusList from './ProspectStatusList'
import { useApi } from './ApiProvider'
import { IconFileDescription } from '@tabler/icons-react'
import { Skeleton } from 'antd'
import ApplicantViewButton from './ApplicantViewButton'
import { Pagination, PaginationItem } from '@mui/material'
import ApplicantEditButton from './ApplicantEditButton'

const FollowupPending = ({dropDown, officeId}) => {

    const store = userStore()
    const navigate = useNavigate()

    const [result, setResult] = useState([])
    const [loader, setLoader] = useState(true)
    const [user, setUser] = useState("")
    const [office, setOffice] = useState("")
    const [status, setStatus] = useState("")
    const [leadStatus, setLeadStatus] = useState("")
    const [currentPage, setCurrentPage] = useState(0)

    const { apiData } = useApi();

    const followupRef = useRef(null);

    const handleChange = (event, value) => {
        setCurrentPage(() => value - 1)
        setTimeout(() => {
            followupRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 0);
    }
    
    const followUpOffice = authQueries.useFollowUpPendingOfficeWiseMutation(
		async (response) => {
      	if(response?.message === "success"){
              setLoader(false)
              setResult(response?.data)
        } 
    });

    useEffect(() => {
        followUpOffice.mutateAsync({office: store?.user?.role === "RM" ? officeId : office, status: status, user: user, leadStatus: leadStatus, page: currentPage})
    }, [apiData, office, user, status, leadStatus, currentPage, officeId]);


    const handleReset =()=>{
        setCurrentPage(0)
    }


  return (
    <Fragment>
        <div className="followup-hding-section mb-2" ref={followupRef}>
            <div className='row'>
                <div className="col-md-4 flex followup-wrpr">
                    <div className="col-md-12" style={{display:'flex', alignItems:'center', gap:'4px'}}>
                        <i className="followup-icon">
                            <IconFileDescription size="30" className='text-[#7D6FAB]' />
                        </i>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                                <span className="text-[16px] font-medium">Follow Up Pending</span>	
                                <span style={{background:'gold', borderRadius:'30px', height:'25px', width:'25px', display:'flex', alignItems:'center', justifyContent:'center', fontSize:'12px'}}>{result?.totalItems}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className='col-md-3'>
                                {
                                    store?.user?.role !== "RM" &&
                                    <select
                                    onChange={(e)=>{setOffice(e.target.value); handleReset()}}
                                    className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                                    >
                                        <option value="">Select Office</option>
                                        {
                                            store?.user?.office?.map((item, index)=> (
                                                <option value={item?.oid} key={index}>{item?.office?.name}</option>
                                            ))
                                        }
                                    </select>
                                }
                            </div>
                            <div className='col-md-3'>
                                <select
                                onChange={(e)=>{setUser(e.target.value); handleReset()}}
                                className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                                >
                                    <option value="">Select User</option>
                                    {
                                        dropDown?.data?.user?.map((item, index)=>(
                                            <option value={item?.id} key={index}>{item?.userdetail?.firstname} {item?.userdetail?.lastname}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='col-md-4'>
                                <select
                                onChange={(e)=>{setStatus(e.target.value); handleReset()}}
                                className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                                >
                                    <option value="">Select Status</option>
                                    {
                                        dropDown?.data?.status?.map((item, index)=>(
                                            <option value={item?.id} key={index}>{item?.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='col-md-2'>
                                <select
                                onChange={(e)=>{setLeadStatus(e.target.value); handleReset()}}
                                className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                                >
                                    <option value="">All Status</option>
                                    <option value="REG">Registered Clients</option>
                                    <option value="HOT">Hot Clients</option>
                                    <option value="WARM">Warm Clients</option>
                                    <option value="COLD">Cold Clients</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <div className="table-wrpr">
                {
                    loader ?
                    <Skeleton active />:
                        <div className="table-section">
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table prospect-table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Profile Status</th>
                                                <th>Office</th>
                                                <th>Date Created</th>
                                                <th>Applicant Name</th>
                                                <th>Contact No.</th>
                                                <th>Visa Type</th>
                                                <th>Intake</th>
                                                <th>Counselor</th>
                                                <th>Assigned to</th>
                                                <th>Reg Date</th>
                                                <th>Prospect Status</th>
                                                <th>Referred By</th>
                                                <th>Followup Date</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {result?.items?.map((element, index) => {
                                                const item = element?.application 
                                                const assign = element?.assignId?.userdetail
                                                const assignUser = item?.assignUser?.CounselorAssign?.userdetail
                                                return(
                                                    <tr key={index}>
                                                        <td>{(index + 1) + (result?.currentPage * 25)}</td>
                                                        <td>
                                                            <LeadStatus item={item} />
                                                        </td>
                                                        <td>{item?.office?.name}</td>
                                                        <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                                                        <td>
                                                            {item?.applicant_name} {item?.middlename} {item?.lastname}
                                                        </td>
                                                        <td>
                                                            <div className="email-mobile flex flex-col">
                                                                <div>{item.mobile_no}</div>
                                                                <div>{item.landline_no}</div>
                                                                <div>{item.whatsapp_no}</div>
                                                            </div>    
                                                        </td>
                                                        <td>{item?.visatype?.country?.icon} {item?.visatype?.description}</td>
                                                        <td>{item?.intake?.intake}</td>			
                                                        <td>{assignUser?.firstname} {assignUser?.lastname}</td>
                                                        <td>{assign?.firstname} {assign?.lastname}</td>
                                                        <td>{item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}</td>		
                                                        <td>
                                                            <ProspectStatusList item={item} />
                                                        </td>
                                                        <td>{item?.refferal?.refferal}</td>
                                                        <td>{element?.date_enable === "YES" ? moment(element?.followupdate).format('DD-MM-YYYY hh:mm A') : moment(element?.followupdate).format('DD-MM-YYYY')}</td>				
                                                        <td className='flex justify-end'>
                                                            <div className='grid grid-cols-12 w-[130px] gap-1'>
                                                                <div className='col-span-12'>
                                                                    <FollowUp item={item}  />
                                                                </div>
                                                                <div className='col-span-12 grid grid-cols-12 gap-1'>
                                                                    <div className="col-span-6">
                                                                        <ApplicantEditButton followup item={item}  />
                                                                    </div>
                                                                    <div className="col-span-6">
                                                                        <ApplicantViewButton item={item}  />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            )}
                                        </tbody>
                                    </table>
                                    {
                                        result?.totalPages > 1 &&
                                        <ul className="pagination pagination-sm mt-2 justify-content-center table-pagination custom-pagination">
                                            <Pagination
                                                count={result?.totalPages}
                                                renderItem={
                                                    (item) => (
                                                        <div>
                                                        <PaginationItem
                                                        component="li"
                                                        {...item}
                                                        className={`!bg-white !text-[12px] ${item.selected && '!border-rose-500'}`}
                                                        />
                                                        </div>
                                                    )
                                                }
                                                variant="outlined"
                                                page={result?.currentPage + 1}
                                                shape="rounded"
                                                onChange={handleChange}
                                            />
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                }
            </div>


    </Fragment>
  )
}

export default FollowupPending