import { Autocomplete, Pagination, PaginationItem, TextField } from '@mui/material'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../queries/authQueries'
import { useForm } from 'react-hook-form'
import { Empty, Popconfirm } from 'antd'
import { usePaginationStore, userStore } from '../../store/userStore'
import { Button, Checkbox, Modal } from '@nextui-org/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import LoaderCommon from '../Common/LoaderCommon'
import LeadStatus from '../Common/LeadStatus'
import { useAdvancedSearch } from '../../store/advancedSearchStore'
import PaginationComponent from '../Common/PaginationComponent'
import { useApi } from '../Common/ApiProvider'
import AdvancedSearch from '../Common/AdvancedSearch'

const TravelCounselorApplicant = () => {

    const [result, setResult] = useState([])

    const { apiData, setApiDataAndNotify } = useApi();
    const data = useAdvancedSearch((state) => state);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
    const currentPage = usePaginationStore((state) => state.currentPage);

    const applicantList = authQueries.useApplicantAssignMutationList(
        (response)=> {
            setResult(response)
        }
    )

    useEffect(()=>{
        applicantList.mutateAsync({
            page:currentPage, 
            ...data
        })
        console.log(data)
    },[currentPage, apiData])
    
    const confirmApplicant =(e, id)=> {
        confirmApp.mutateAsync({id:id, type:'TRAVEL'})
    }

    const confirmApp = authQueries.useApplicantExeConfirmMutation(
        (res)=> {
            setApiDataAndNotify(res)
        }
    )

  return (
    <div>
        <div className="breadcrumb-and-otherbuttons">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Clients</li>
                </ol>
            </nav>
        </div>
				
        <div className="add_clients">
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <h2><span>My</span>Clients</h2>	
                </div>
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>
            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header">
                        <a className="collapsed card-link"  href="" style={{pointerEvents:'none'}}>
                            Student Requires Travel Assistance
                        </a>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">   


                            <AdvancedSearch />

                            <div className="table-list-search-wrpr simple-search mt-3">

                                <div className="row">
                                    <div className="col-md-12 search-results-count-wrpr">
                                        Search Results {result?.data?.items?.length} of {result?.data?.totalItems}
                                    </div>
                                </div>

                                {applicantList?.isLoading ?
                                    <LoaderCommon />:
                                    <Fragment>
                                        {result?.data?.totalItems > 0 &&
                                            <div className="table-wrpr">
                                                <div className="table-section">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table className="table prospect-table mb-0">
                                                                <thead>
                                                                    <tr className='alt-colors'>
                                                                        <th width="30">#</th>
                                                                        <th>Profile Status</th>
                                                                        <th>Office</th>
                                                                        <th>Date Created</th>
                                                                        <th>Applicant Name</th>
                                                                        <th>Contact Number</th>
                                                                        <th>Visa Type</th>
                                                                        <th>Intake</th>
                                                                        <th>Reg. Date</th>
                                                                        <th>Prospect Status</th>
                                                                        <th>Referred By</th>
                                                                        <th width='150'>&nbsp;</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="ui-sortable">
                                                                    {result?.data?.items?.map((item, index) => (
                                                                        <tr className="ui-sortable-handle" key={index}>
                                                                            <th className="">{(index+1) + (result?.data?.currentPage * 25)}</th>
                                                                            <td>
                                                                                <LeadStatus item={item} />
                                                                            </td>
                                                                            <td>{item?.office?.name}</td>
                                                                            <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                            <td>
                                                                                {item?.applicant_name} {item?.middlename} {item?.lastname} 
                                                                            </td>
                                                                            <td>
                                                                                <div className="email-mobile flex flex-col">
                                                                                    <div>{item.mobile_no}</div>
                                                                                    <div>{item.landline_no}</div>
                                                                                    <div>{item.whatsapp_no}</div>
                                                                                </div>                                                                    
                                                                            </td>
                                                                            <td>{item?.visatype?.description}</td>
                                                                            <td>{item?.intake?.intake}</td>
                                                                            <td>
                                                                                {item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                                            </td>
                                                                            <td>
                                                                                {item?.status_of_prospect?.name}
                                                                            </td>
                                                                            <td>{item?.refferal?.refferal}</td>
                                                                            <td>
                                                                                <Popconfirm
                                                                                placement="left"
                                                                                title={item?.travel === "NO" ? "Are you sure student requires travel assistance?" : "Are you sure student not requires travel assistance?" }
                                                                                onConfirm={(event) => {confirmApplicant(event, item.id)}}
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                                >
                                                                                    <Checkbox isSelected={item?.travel === "YES" ? true:false}>
                                                                                        <span style={{fontSize:'12px', fontWeight: 400}}>Student Requires Travel Assistance</span>
                                                                                    </Checkbox>
                                                                                </Popconfirm>
                                                                            </td>
                                                                        </tr>
                                                                    ))}												  
                                                                </tbody>
                                                            </table>
                                                            
                                                            <PaginationComponent result={result}  setCurrentPage={setCurrentPage} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            result?.data?.totalItems === 0 &&
                                            <div style={{marginTop:'5%', marginBottom: '10%'}} className="text-center flex justify-center">
                                                <Empty
                                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                                    imageStyle={{ height: 80, display:'flex', justifyContent:'center' }}
                                                    description={
                                                    <h6>
                                                        Sorry! No Leads found.
                                                    </h6>
                                                    }
                                                >
                                                </Empty>
                                            </div>
                                        }
                                    </Fragment>
                                }
                            </div>

                        </div>
                    </div>
                </div>               
            </div>
        </div>
    </div>
  )
}

export default TravelCounselorApplicant