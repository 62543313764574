/* eslint-disable jsx-a11y/anchor-is-valid */
// import { decode } from 'html-entities'
import React, { Fragment, useEffect, useState } from 'react'
import { useLoaderStore, usePaginationStore, userStore } from '../../store/userStore';
import moment from 'moment';
import LoaderCommon from '../Common/LoaderCommon';
import PaginationComponent from '../Common/PaginationComponent';
import FollowUp from '../Common/FollowUp';
import LeadStatus from '../Common/LeadStatus';
import ProspectStatusList from '../Common/ProspectStatusList';
import ApplicantViewButton from '../Common/ApplicantViewButton';
import ApplicantEditButton from '../Common/ApplicantEditButton';
import { useApi } from '../Common/ApiProvider';
import AdvancedSearch from '../Common/AdvancedSearch';
import { useAdvancedSearch } from '../../store/advancedSearchStore';
import { IconClearAll } from '@tabler/icons-react';
import { Empty } from 'antd';
import { decode } from 'html-entities';
import followupQueries from '../../queries/followupQueries';

const AllFollowupPending = () => {

    const [result, setResult] = useState([]);
    const [active, setActive] = useState({ index: null, status: false });
    const user = userStore((state)=> state.user);
    const data = useAdvancedSearch((state) => state);
    const { apiData } = useApi()
    const loader = useLoaderStore((state) => state.loader);
    const setLoader = useLoaderStore((state) => state.setLoader);
    const resetPage = usePaginationStore((state) => state.resetPage);
    const resetAll = useAdvancedSearch((state)=> state.resetAll)
    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    useEffect(() => {
        setLoader(true)
    }, [data, currentPage]);

    const applicantListing = followupQueries.useFollowupPending(
        (response) => {
            setResult(response)
            setLoader(false)
        }
    );

    useEffect(() => {
      applicantListing.mutateAsync({
          page: currentPage,
          size: 25,
          ...data,
      })
    }, [data, currentPage, apiData])

    const resetFilters =()=>{
        resetAll()
        resetPage()
    }

    const counselorName =(item)=> {
        if(!item) return
        const name = item?.firstname +" "+ item?.lastname 
        return name
	  }


    return (
        <div>

            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Leads Since Last Follow up</li>
                    </ol>
                </nav>
            </div>

            <div className="add_clients" style={{borderBottom:'0px'}}>
                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <i className="appointments-icon">
                            <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                        </i>
                        <h2><span>Leads Since Last</span>Follow up</h2>
                    </div>
                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>

                <div id="accordion" className="add_clients_accordion_form">

                    <div className="card" id="appointments_wrpr">
                        <div className="card-header flex justify-between">
                            <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                                Leads Since Last Follow up
                            </a>
                            <div>
                                <button className='text-[12px] flex gap-2 items-center' onClick={resetFilters}>
                                    <IconClearAll size={17} />
                                    Clear Filters
                                </button>
                            </div>
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className='col-md-12'>
                                
                                    <AdvancedSearch />

                                    <div className="search-results-count-wrpr">
                                        {
                                        result?.data?.totalItems > 0 ?
                                        `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                        `O results found`
                                        }
                                    </div>

                                    <div className="">
                                        {loader ? 
                                        <LoaderCommon />:
                                        result?.data?.totalItems > 0 ?
                                            <div className="table-wrpr">
                                                <div className="table-section">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table className="table prospect-table mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="30">#</th>
                                                                        <th>Profile Status</th>
                                                                        <th>Office</th>
                                                                        <th>Date Created</th>
                                                                        <th>Applicant Name</th>
                                                                        <th>Contact Number</th>
                                                                        <th>Visa Type</th>
                                                                        <th>Intake</th>
                                                                        <th>Counselor</th>
                                                                        {
                                                                          (user?.role === "ADMIN" || user?.role === "CEO") &&
                                                                          <th>Assigned To</th>
                                                                        }
                                                                        <th>Reg. Date</th>
                                                                        <th>Prospect Status</th>
                                                                        <th>Referred By</th>
                                                                        <th width='150'>&nbsp;</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="ui-sortable">
                                                                    {result?.data?.items?.map((element, index) => {
                                                                      const item = element?.application
                                                                      return(
                                                                        <Fragment key={index}>
                                                                            <tr className="ui-sortable-handle">
                                                                                <td>{(index + 1) + (result?.data?.currentPage * 25)}</td>
                                                                                <td>
                                                                                    <LeadStatus item={item} />
                                                                                </td>
                                                                                <td>{item?.office?.name}</td>
                                                                                <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                                <td>{decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)}
                                                                                </td>
                                                                                <td>
                                                                                    <div className="email-mobile flex flex-col">
                                                                                        <div>{item?.mobile_no}</div>
                                                                                        <div>{item?.landline_no}</div>
                                                                                        <div>{item?.whatsapp_no}</div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{item?.visatype?.country?.icon} {item?.visatype?.description}</td>
                                                                                <td>{item?.intake?.intake}</td>
                                                                                <td>
                                                                                    {counselorName(item?.assignUser?.CounselorAssign?.userdetail)}
                                                                                </td>
                                                                                {
                                                                                  (user?.role === "ADMIN" || user?.role === "CEO") &&
                                                                                  <td>{counselorName(element?.assignId?.userdetail)}</td>
                                                                                }
                                                                                <td>
                                                                                    {item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                                                </td>
                                                                                <td>
                                                                                    <ProspectStatusList item={item} />
                                                                                </td>
                                                                                <td>{item?.refferal?.refferal}</td>
                                                                                <td>
                                                                                    <div className="grid grid-cols-12 gap-1 w-[130px]">
                                                                                        <div className='col-span-12'>
                                                                                            <FollowUp item={item} />
                                                                                        </div>
                                                                                        <div className="col-span-6">
                                                                                            <ApplicantEditButton item={item} />
                                                                                        </div>
                                                                                        <div className="col-span-6">
                                                                                            <ApplicantViewButton item={item} />
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </Fragment>
                                                                      )}
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                            <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>:
                                            <div className='mt-24 mb-24'>
                                                <Empty
                                                description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>Clients not found!</div>}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AllFollowupPending