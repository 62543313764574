import { Modal } from '@nextui-org/react'
import React, { Fragment, useState } from 'react'
import visatypeCountQueries from '../../queries/visatypeCountQueries'
import LoaderCommon from '../Common/LoaderCommon'

const NotInterestedOrQualified = ({data, label}) => {
    
    const [open, setOpen] = useState(false)
    const [result, setResult] = useState([])
    const [loader, setLoader] = useState(false)

    const handleOpen =()=> {
        setLoader(true)
        setOpen(true)
        getData.mutateAsync(data)
    }

    const handleClose =()=> {
        setOpen(false)
        setLoader(true)
    }

    const getData = visatypeCountQueries.useNotinterestedOrQualified(
        (res)=>{
            setLoader(false)
            setResult(res?.data)
        }
    )

  return (
    <Fragment>
        <button 
        onClick={handleOpen}
        className='btn-customized-large'
        >Not {label} client’s consolidated view </button>
        <Modal
        scroll
        width="800px"
        open={open}
        onClose={handleClose}
        closeButton
        >
            <Modal.Header>
                <div className='text-[18px] font-medium'>
                    Not {label} client’s consolidated view 
                </div>
            </Modal.Header>
            <Modal.Body>

                {
                    loader ?
                    <LoaderCommon /> :
                    <table className='table-dotted w-full'>
                        <thead>
                            <tr className='table-row'>
                                <th>Visa Type</th>
                                <th>No of Applicants</th>
                            </tr>
                        </thead>
                        <tbody>
                            {result?.map((item, index) => {
                                const indexOdd = index % 2 === 0
                                return(
                                    <tr key={index} className={`table-row ${indexOdd ? 'bg-[#9a9bee]' : ''}`}>
                                        <td>{item?.name}</td>
                                        <td className='py-3 text-center'>{item?.count}</td>
                                    </tr>
                                )}
                            )}
                            <tr className='table-row bg-[#ffb172]'>
                                <td>TOTAL</td>
                                <td className='py-3 text-center !text-[16px]'>{result?.reduce((a, b) => a + b.count, 0)}</td>
                            </tr>
                        </tbody>
                    </table>
                }

            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default NotInterestedOrQualified
