import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import InputSearch from "../../../Common/InputSearch";
import SelectSearch from "../../../Common/SelectSearch";
import LoaderCommon from "../../../Common/LoaderCommon";
import { GanttChartSquare } from "lucide-react";
import reportQueries from "../../../../queries/reportQueries";
import toast from "react-hot-toast";


const AdmissionReport =()=>{

    const [result, setResult] = useState([]);

    const today = moment().format('YYYY-MM-DD')

    const [fromDate, setFromDate] = useState(today)
    const [toDate, setToDate]= useState(today)
    const [country, setCountry] = useState(null)

    
    const report = reportQueries.useAdmissionStaffPerformanceReport(
        (response) => {
            if(response?.success){
                setResult(response?.data);   
            }
        }
    );

    const dropdown = reportQueries.useAdmissionStaffPerformanceReportDropdown(
        (response) => {
        }
    ) 
    useEffect(()=>{
        report.mutateAsync({
            from: fromDate,
            to: toDate,
            country_id: country
        })
        dropdown.mutateAsync()
    }, [])

    const searchFields = [
        {
            label: "Country",
            size: 2,
            state: country,
            setState: setCountry,
            input: SelectSearch,
            value: dropdown?.data?.country,
            name: 'name'
        },
        {
            label: "From Date",
            size: 2,
            state: fromDate,
            setState: setFromDate,
            input: InputSearch,
            type: "date",
            name: 'name'
        },
        {
            label: "To Date",
            size: 2,
            state: toDate,
            setState: setToDate,
            input: InputSearch,
            type: "date",
            name: 'name'
        }
    ]

    const onSearch =()=>{
        report.mutateAsync({
            from: fromDate,
            to: toDate,
            country_id: country
        })
    }

    return(
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Admission Staff Performance</li>
                    </ol>
                </nav>
            </div>
            
            <div className="add_clients">
                <div className="flex items-center gap-[4px] mb-4">
                    <i className="followup-icon">
                        <GanttChartSquare size="25" className='text-[#7D6FAB]' />
                    </i>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                            <span className="text-[16px] font-medium">Admission Staff Performance</span>	
                        </div>
                    </div>
                </div>

                <div id="accordion" className="add_clients_accordion_form mt-[10px]">
                    <div  className="card" id="appointments_wrpr_employee">
                        <div className="card-header">
                            Admission Staff Performance
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">

                            <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5"> 
                                {
                                    searchFields?.map((item, index)=>{
                                        return(
                                            <div key={index} className="col-span-2 flex flex-col gap-2">
                                                <item.input item={item} />
                                            </div>
                                        )
                                    })
                                }
                                <div className="col-span-1 flex flex-col gap-2">
                                    <div className="text-[12px] h-4 font-[500]" />
                                    <button onClick={onSearch} className='rounded-md text-[12px] !w-full !min-h-[42px] custom-color border-none text-white'>
                                        Search
                                    </button>
                                </div>
                            </div>

                                {report.isLoading ?
                                    <LoaderCommon />:
                                    <div className="overflow-x-auto max-h-[600px]">    
                                        <table border={1} className="table report associate-report collageprogram overflow-auto">
                                            <thead className="sticky top-0" style={{zIndex:100}}>
                                                <tr className='text-center'>
                                                    <th className="min-w-[50px] text-center fixed-column data py-2">#</th>
                                                    <th className="min-w-[300px] !text-[13px] fixed-column text-left data" style={{backgroundColor:"var(--primary-color)"}}>Admission Staff</th>
                                                    <th className="min-w-[100px] !text-[13px] text-center py-2">Total Assigned Clients</th>
                                                    <th className="min-w-[100px] !text-[13px] text-center py-2">Full Docs</th>
                                                    <th className="min-w-[100px] !text-[13px] text-center py-2">Application submitted</th>
                                                    <th className="min-w-[100px] !text-[13px] text-center py-2">Conditional Offer Received</th>
                                                    <th className="min-w-[100px] !text-[13px] text-center py-2">Unconditional Offer Received</th>
                                                    <th className="min-w-[100px] !text-[13px] text-center py-2">Application rejected</th>
                                                    <th className="min-w-[100px] !text-[13px] text-center py-2">Not Proceeding Offer/Application</th>
                                                    <th className="min-w-[100px] !text-[13px] text-center py-2">Offer Accepted</th>
                                                    <th className="min-w-[100px] !text-[13px] text-center py-2">Total Active Applications</th>
                                                    <th className="min-w-[100px] !text-[13px] text-center py-2">Total Active Applicant</th>
                                                </tr>
                                            </thead>
                                                {   
                                                    result?.map((item, index) =>(
                                                        <tbody key={index} className={`ui-sortable ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                                                            <tr key={index} className="ui-sortable-handle">
                                                                <td className="!text-[13px] text-center fixed-column data">{index + 1}</td>
                                                                <td className="!text-[13px] text-left fixed-column data bg-white">
                                                                    {item?.name}
                                                                </td>
                                                                <td className="min-w-[100px] !text-[13px] text-center py-2">{item?.activeTotalAssigned}</td>
                                                                <td className="min-w-[100px] !text-[13px] text-center py-2">{item?.fullDoc}</td>
                                                                <td className="min-w-[100px] !text-[13px] text-center py-2">{item?.submitted}</td>
                                                                <td className="min-w-[100px] !text-[13px] text-center py-2">{item?.conditional}</td>
                                                                <td className="min-w-[100px] !text-[13px] text-center py-2">{item?.unconditional}</td>
                                                                <td className="min-w-[100px] !text-[13px] text-center py-2">
                                                                    {item?.reject}
                                                                </td>
                                                                <td className="min-w-[100px] !text-[13px] text-center py-2">
                                                                    {item?.notproceed}
                                                                </td>
                                                                <td className="min-w-[100px] !text-[13px] text-center py-2">{item?.accepted}</td>
                                                                <td className="min-w-[100px] !text-[13px] text-center py-2">{item?.totalApplication}</td>
                                                                <td className="min-w-[100px] !text-[13px] text-center py-2">{item?.totalApplicant}</td>
                                                            </tr>
                                                        </tbody>
                                                    ))
                                                }
                                                
                                        </table>
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AdmissionReport;