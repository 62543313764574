import React, { Fragment, useState } from 'react'
import authQueries from '../../queries/authQueries'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { generateValidationSchema } from './validationSchema';
import { Modal } from '@nextui-org/react';
import moment from 'moment';
import { useApi } from './ApiProvider';
import AssignModal from './AssignModal';
import { IconBuildingSkyscraper, IconCalendarMonth, IconDeviceMobileFilled, IconPlane, IconUserFilled } from '@tabler/icons-react';

const RegistrationModal = ({item}) => {

    const [isChanged, setIsChanged] = useState(false)

    const inputFields = [
        {
            name: 'comment',
            required: true,
            min: 10,
            max: 256,
            label: 'Comment'
        },
        {
            name: 'date',
            required: true,
            label: 'Date'
        }
    ]

    const [open, setOpen] = useState(false)

    const { setApiDataAndNotify } = useApi()

    const { register, setValue, handleSubmit, formState:{ errors }} = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const handleOpen =()=> {
        setOpen(true)
    }

    const handleClose =()=> {
        setOpen(false)
        setIsChanged(false)
        if(isChanged){
            setApiDataAndNotify(new Date())
        }
    }

    const onSubmit = (data) => {
        const dataValues = {    
            id: item?.id,
            comment: data.comment,
            date: data.date
        }

        const statusData = {
            applicant_id: item?.id,
            prospects_id: 33,
            comments: data.comment,
            status: "ACTIVE"
        }

        addProspect.mutateAsync(statusData);
        markRegistration.mutateAsync(dataValues)
    }

    const addProspect = authQueries.useProStatusAdd(
        (response) => {
            setValue('comment', '')
            setValue('date', moment('yyyy-mm-dd'))
        }
    );

    const markRegistration = authQueries.useApplicationMutationMarkRegistration(
        (response) => {
            setIsChanged(true)
        }   
    )

    const reAssignFinder = (item) => {
        let arr = [6,3,7,8,9]
        if(item?.visatype?.sop){
            arr.push(22)
        }
        if(item?.visatype?.doc){
            arr.push(5)
        }
        return arr
    }

  return (
    <Fragment>
        <button onClick={handleOpen} 
        className="btn-customized">Mark Registration</button>
        <Modal
        scroll
        blur
        width="550px"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
        onClose={handleClose}
        >
            <Modal.Body>
                <div className='mt-2'>
                    <div className="flex justify-between">
                        Mark Registration
                        <button onClick={handleClose} className='text-[12px] text-[#f00]'>Close</button>
                    </div>
                    <div className='flex gap-2 flex-wrap items-center text-[12px] font-semibold mt-3 rounded-[10px]'>
                        <div className="flex gap-2 items-center">
                            <IconUserFilled className='text-[#563A9C]' size={20} />
                            {item?.applicant_name} {item?.middlename} {item?.lastname}
                        </div>
                        <div className='w-[5px] h-[5px] bg-gray-200 rounded-full' /> 
                        <div className="flex gap-2 items-center">
                            <IconDeviceMobileFilled className="text-[#00712D]" size={20} />
                            {item?.mobile_no}
                        </div>
                        <div className='w-[5px] h-[5px] bg-gray-200 rounded-full' /> 
                        <div className="flex gap-2 items-center">
                            <IconBuildingSkyscraper color="#5F6F65" size={20} />
                            {item?.office?.name}
                        </div>
                        <div className='w-[5px] h-[5px] bg-gray-200 rounded-full' /> 
                        <div className="flex gap-2 items-center">
                            <IconPlane color="#FF885B" size={20} />
                            {item?.visatype?.description}
                        </div>
                        <div className='w-[5px] h-[5px] bg-gray-200 rounded-full' /> 
                        <div className="flex gap-2 items-center">
                            <IconCalendarMonth color="#37AFE1" size={20} />
                            {item?.intake?.intake}
                        </div>
                    </div>
                </div>
                {
                    !isChanged ?
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>                      
                            <div>
                                <label className="text-[12px] h-4 font-[500]">Registration Date:</label>
                                <input
                                max={moment().format('YYYY-MM-DD')}
                                style={{height:'44px'}} 
                                {...register('date')} 
                                name="date" 
                                type="date" 
                                className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                                />
                                <div style={{marginTop:'5px'}}>{errors.comment && <span style={{color:"red", fontSize:'13px'}}>{errors?.date?.message}</span>}</div>
                            </div>
                            <div>
                                <label className="text-[12px] h-4 font-[500]">Registration Comment:</label>
                                <textarea
                                {...register('comment')}
                                name="comment"
                                className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                                placeholder="Enter Comment"
                                style={{ minHeight: '100px' }}
                                />
                                <div style={{marginTop:'5px'}}>{errors.comment && <span style={{color:"red", fontSize:'13px'}}>{errors?.comment?.message}</span>}</div>
                            </div>
                            <button className='btn-customized-large w-full' type="submit">
                                Mark Registration
                            </button>
                        </form>
                    </div>:
                    <div className='w-full flex flex-col items-center justify-center gap-10'>
                        
                        <div className="font-sans text-gray-800 text-center">
                            <p className="text-green-600 font-semibold">
                                Application successfully registered.
                            </p>
                            <p>
                                Click <span className="text-blue-500 font-semibold">'Assign/Reassign'</span> to continue, 
                                or <span className="text-red-500 font-semibold">close</span> to proceed without assigning. 
                                <span className="text-gray-500">It can be assigned later if needed.</span>
                            </p>
                        </div>

                        
                        <div className='w-[200px]'>
                            <AssignModal
                            fontSize={"14px"}
                            className="btn-customized-large w-full"
                            item={item} 
                            roles={reAssignFinder(item)}
                            selectedRole={3} 
                            label="Assign / Reassign" 
                            noOffice={true}
                            />
                        </div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer />
        </Modal>
    </Fragment>
  )
}

export default RegistrationModal
